import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import MadeBy from "./MadeBy";

const Page = pageContent => props => {
    console.log("pageprops --> ", props);
    const component = () => pageContent;

    return (
        <>
            <Header />
            {React.createElement(component, { ...props })}
            <Footer />
            <MadeBy />
        </>
    );
};
export default Page;
