// Modules
import React, { useState, useEffect, useRef, useCallback } from 'react';

// Styling
import './exchange.scss';
import './exchange-mobile.scss';

import dgaLogoSVG from '../../img/logo-groene-inruildeals.svg';
import Modal from 'react-bootstrap/Modal';
import cx from 'classnames';
import Helmet from 'react-helmet';
import axios from 'axios';

// Hook
function useEventListener(eventName, handler, element = window) {
    // Create a ref that stores handler
    const savedHandler = useRef();

    // Update ref.current value if handler changes.
    // This allows our effect below to always get latest handler ...
    // ... without us needing to pass it in effect deps array ...
    // ... and potentially cause effect to re-run every render.
    useEffect(
        () => {
            savedHandler.current = handler;
        },
        [handler]
    );

    useEffect(
        () => {
            // Make sure element supports addEventListener
            // On
            const isSupported = element && element.addEventListener;
            if (!isSupported) return;

            // Create event listener that calls handler function stored in ref
            const eventListener = event => savedHandler.current(event);

            // Add event listener
            element.addEventListener(eventName, eventListener);

            // Remove event listener on cleanup
            return () => {
                element.removeEventListener(eventName, eventListener);
            };
        },
        [eventName, element] // Re-run if eventName or element changes
    );
}

export class OfferteModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            spinner: false,
            formComplete: false,
        };
    }

    requestComplete = response => {
        this.setState({ spinner: false, formComplete: true });
        console.log(JSON.parse(response));
    };

    handleSubmit = e => {
        const { requestComplete } = this;
        e.preventDefault();
        const formData = new FormData();

        formData.set('Naam', e.target['Naam'].value);
        formData.set('Bedrijf', e.target['Bedrijf'].value);
        formData.set('Telefoonnummer', e.target['Telefoonnummer'].value);
        formData.set('E-mailadres', e.target['E-mailadres'].value);
        formData.set('Vermogen', e.target['Vermogen'].value);
        formData.set('Merk-inruilaggregaat', e.target['Merk-inruilaggregaat'].value);
        formData.set('Jaar-inruilaggregaat', e.target['Jaar-inruilaggregaat'].value);
        formData.set('Aantal-draaiuren', e.target['Aantal-draaiuren'].value);

        let els = document.querySelectorAll('input[type=file]');
        let i = 0;
        for (i = 0; i < els.length; i++) {
            // Make sure the file has content before append it to the form object.
            let file = els[i];

            if (file.files.length > 0) {
                let fileData = file.files[0];

                // Only process image files.
                if (!fileData.type.match('image.*')) {
                    continue;
                }

                // Appends the currently selected File of an <input type="file" id="file"> element the FormData instance
                // which is later to be sent as multipart/form-data XHR request body
                if (i === 0) {
                    formData.append('Foto-binnenzijde', fileData);
                } else {
                    formData.append('Foto-buitenzijde', fileData);
                }
            }
        }

        this.setState({ spinner: true });

        // Bind this to that so that Promiuse can set state

        axios({
            method: 'post',
            url: 'https://thegreengenerators.com/phpmailer/green-deals-form.php',
            data: formData,
            config: { headers: { 'Content-Type': 'multipart/form-data' } },
        })
            .then(function(response) {
                //handle success
                console.log('SUCCESS!!!!!');
                return JSON.stringify(response);
            })
            .catch(function(response) {
                //handle error
                return JSON.stringify(response);
            })
            .then(requestComplete);
    };
    render() {
        return (
            <Modal
                backdrop={this.props.visibility}
                show={this.props.visibility}
                onHide={() => {
                    console.log('hide?');
                    this.props.onClick();
                }}
            >
                <Modal.Body>
                    <div
                        className={cx('modal-question', {
                            'modal-question-open': this.props.visibility,
                            'modal-question-close': !this.props.visibility,
                        })}
                    >
                        <div className={'bug-free-scroll'}>
                            <button
                                className="modal-question__button-close modal-question__button-close--invisible site-button button-reset"
                                type="button"
                                onClick={() => {
                                    this.setState({ spinner: false, formComplete: false });
                                    this.props.onClick();
                                }}
                            >
                                <i className={'material-icons'}>close</i>
                                <span>Sluit</span>
                            </button>

                            <h2 className="modal-question__heading">{this.props.title || 'Green deals'}</h2>
                            <p className="modal-small-intro">
                                Wij uw vervuilende aggregaat, u een duurzaam hybride aggregaat én een aantrekkelijke inruilkorting. Dat is de kern van onze unieke inruildeal. Als u
                                het formulier hieronder invult en naar ons verzendt, ontvangt u van ons z.s.m. een aantrekkelijk inruilvoorstel.
                            </p>
                            <div>
                                {this.state.formComplete ? (
                                    <p style={{ marginTop: '35px', fontWeight: 'bold', backgroundColor: '#B8E4C9', padding: '15px 5px ' }}>
                                        Hartelijk dank voor uw bericht! We nemen zo spoedig mogelijk contact met u op.
                                    </p>
                                ) : this.state.spinner ? (
                                    <p>Offerte verzenden...</p>
                                ) : (
                                    <form className="question-form" onSubmit={this.handleSubmit} method="post" encType={'multipart/form-data'}>
                                        <p className="question-form__intro">Vraag offerte aan:</p>
                                        <div className="form-container">
                                            <input type="text" required name="Naam" placeholder="Naam" autoFocus={true} />
                                            <input type="text" required name="Merk-inruilaggregaat" placeholder="Merk inruilaggregaat" />
                                            <input type="text" required name="Bedrijf" placeholder="Bedrijf" />
                                            <input type="tel" required name="Telefoonnummer" placeholder="Telefoonnummer" />
                                            <input type="email" required name="E-mailadres" placeholder="E-mailadres" />
                                            <input type="text" required name="Jaar-inruilaggregaat" placeholder="Jaar inruilaggregaat" />
                                            <input type="text" required name="Aantal-draaiuren" placeholder="Aantal draaiuren" />
                                            <label htmlFor={'upload-inside'}>Upload foto binnenzijde</label>
                                            <input id={'upload-inside'} required type="file" accept=".png, .jpg" name="Foto-binnenzijde" />
                                            <label htmlFor={'upload-outside'}> Upload foto buitenzijde</label>
                                            <input id={'upload-outside'} required type="file" accept=".png, .jpg" name="Foto-buitenzijde" />
                                            <textarea
                                                className="request-form__input request-form__input--textarea"
                                                name="Vermogen"
                                                required
                                                placeholder="Welk vermogen zoekt u voor de nieuwe hybride aggregaat in kVA?"
                                            />
                                            <button type="submit">Verzend</button>
                                        </div>
                                    </form>
                                )}
                            </div>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

const GreenDeals = () => {
    const [visibility, setVisibility] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [formComplete, setFormComplete] = useState(false);

    // State for storing mouse coordinates
    const [height, setHeight] = useState(window.innerHeight - 100);

    // Event handler utilizing useCallback ...
    // ... so that reference never changes.
    const handler = useCallback(
        () => {
            // Update coordinates
            setHeight(window.innerHeight - 100);
        },
        [setHeight]
    );

    // Add event listener using our hook
    useEventListener('resize', handler);

    return (
        <main className="page-main page-green-deals" style={{ height }}>
            <Helmet>
                <title>Ontvang inruilkorting! | The Green Generators</title>
                <meta name="description" content="Ruil uw vervuilende aggregaat bij ons in." />
            </Helmet>
            <OfferteModal visibility={visibility} onClick={() => setVisibility(!visibility)} />
            <section className="green-deals" style={{ height }}>
                <h1 className="visually-hidden">Inruilactie</h1>
                <div className="exchange__wrapper">
                    <div className="discount">
                        <img className="discount__logo" src={dgaLogoSVG} alt="Logo De Groene Aggregaat" width="500" height="220" />
                        <div className="discount__wrapper discount__wrapper--desktop">
                            <b className="discount__red">Ruil uw vervuilende aggregaat bij ons in...</b>
                            <b className="discount__green">en ontvang inruilkorting</b>
                            <br />
                            <b className="discount__blue">op een hybride aggregaat</b>
                        </div>
                        <div className="discount__wrapper discount__wrapper--mobile">
                            <b className="discount__red">
                                Ruil uw vervuilende
                                <br />
                                aggregaat bij ons in...
                            </b>
                            <b className="discount__green">
                                en ontvang
                                <br />
                                inruilkorting op een
                            </b>
                            <b className="discount__blue">hybride aggregaat</b>
                        </div>
                    </div>
                    <button className="exchange__button-mobile" onClick={() => setVisibility(!visibility)} type="button>">
                        Offerte opvragen
                    </button>
                    <div className="offer">
                        <h2 className="offer__heading">vraag een offerte aan</h2>
                        <p className="offer__description">
                            Wij uw vervuilende aggregaat, u een duurzaam hybride aggregaat én een aantrekkelijke inruilkorting. Dat is de kern van onze unieke inruildeal. Als u het
                            formulier hieronder invult en naar ons verzendt, ontvangt u van ons z.s.m. een aantrekkelijk inruilvoorstel.
                        </p>

                        {formComplete ? (
                            <p style={{ marginTop: '35px', fontWeight: 'bold', backgroundColor: '#B8E4C9', padding: '15px 5px ' }}>
                                Hartelijk dank voor uw bericht! We nemen zo spoedig mogelijk contact met u op.
                            </p>
                        ) : spinner ? (
                            <p>Offerte verzenden...</p>
                        ) : (
                            <form
                                className="request-form"
                                onSubmit={e => {
                                    e.preventDefault();
                                    const formData = new FormData();

                                    formData.set('Naam', e.target['Naam'].value);
                                    formData.set('Bedrijf', e.target['Bedrijf'].value);
                                    formData.set('Telefoonnummer', e.target['Telefoonnummer'].value);
                                    formData.set('E-mailadres', e.target['E-mailadres'].value);
                                    formData.set('Vermogen', e.target['Vermogen'].value);
                                    formData.set('Merk-inruilaggregaat', e.target['Merk-inruilaggregaat'].value);
                                    formData.set('Jaar-inruilaggregaat', e.target['Jaar-inruilaggregaat'].value);
                                    formData.set('Aantal-draaiuren', e.target['Aantal-draaiuren'].value);

                                    let els = document.querySelectorAll('input[type=file]');
                                    let i = 0;
                                    for (i = 0; i < els.length; i++) {
                                        // Make sure the file has content before append it to the form object.
                                        let file = els[i];

                                        if (file.files.length > 0) {
                                            let fileData = file.files[0];

                                            // Only process image files.
                                            if (!fileData.type.match('image.*')) {
                                                continue;
                                            }

                                            // Appends the currently selected File of an <input type="file" id="file"> element the FormData instance
                                            // which is later to be sent as multipart/form-data XHR request body
                                            if (i === 0) {
                                                formData.append('Foto-binnenzijde', fileData);
                                            } else {
                                                formData.append('Foto-buitenzijde', fileData);
                                            }
                                        }
                                    }

                                    setSpinner(true);
                                    // this.setState({ spinner: true });
                                    // set spinner state with hooks

                                    // Bind this to that so that Promiuse can set state

                                    axios({
                                        method: 'post',
                                        url: 'https://thegreengenerators.com/phpmailer/green-deals-form.php',
                                        data: formData,
                                        config: { headers: { 'Content-Type': 'multipart/form-data' } },
                                    })
                                        .then(function(response) {
                                            //handle success
                                            console.log('SUCCESS!!!!!');
                                            return JSON.stringify(response);
                                        })
                                        .catch(function(response) {
                                            //handle error
                                            return JSON.stringify(response);
                                        })
                                        .then(() => {
                                            // set th state
                                            setFormComplete(true);
                                        });
                                }}
                                method="post"
                                encType={'multipart/form-data'}
                            >
                                <input
                                    className="request-form__input request-form__input--first-name"
                                    required
                                    type="text"
                                    name="Naam"
                                    aria-label="voornaam"
                                    placeholder="Naam"
                                    autoFocus={true}
                                />
                                <input
                                    className="request-form__input request-form__input--brand"
                                    required
                                    type="text"
                                    name="Merk-inruilaggregaat"
                                    aria-label="merk inruilaggregaat"
                                    placeholder="Merk inruilaggregaat"
                                />
                                <input className="request-form__input request-form__input--last-name" type="text" name="Achternaam" style={{ visibility: 'hidden' }} />
                                <input
                                    className="request-form__input request-form__input--company"
                                    required
                                    type="text"
                                    name="Bedrijf"
                                    aria-label="Bedrijf"
                                    placeholder="Bedrijf"
                                />
                                <input
                                    className="request-form__input request-form__input--phone"
                                    required
                                    type="tel"
                                    name="Telefoonnummer"
                                    aria-label="Telefoonnummer"
                                    placeholder="Telefoonnummer"
                                />
                                <input
                                    className="request-form__input request-form__input--mail"
                                    required
                                    type="email"
                                    name="E-mailadres"
                                    aria-label="e-mailadres"
                                    placeholder="E-mailadres"
                                />
                                <input
                                    className="request-form__input request-form__input--year"
                                    required
                                    type="text"
                                    name="Jaar-inruilaggregaat"
                                    aria-label="jaar inruilaggregaat"
                                    placeholder="Jaar inruilaggregaat"
                                />
                                <input
                                    className="request-form__input request-form__input--hours"
                                    required
                                    type="text"
                                    name="Aantal-draaiuren"
                                    aria-label="aantal draaiuren"
                                    placeholder="Aantal draaiuren"
                                />
                                <label className="request-form__label request-form__label--inner">
                                    Upload foto binnenzijde
                                    <input
                                        className="request-form__file request-form__input--photo-inner"
                                        accept=".png, .jpg"
                                        required
                                        type="file"
                                        name="Foto-binnenzijde"
                                        aria-label="foto binnenzijde"
                                    />
                                </label>
                                <label className="request-form__label request-form__label--outer">
                                    Upload foto buitenzijde
                                    <input
                                        className="request-form__file request-form__input--photo-outer"
                                        accept=".png, .jpg"
                                        required
                                        type="file"
                                        name="Foto-buitenzijde"
                                        aria-label="Foto buitenzijde"
                                    />
                                </label>
                                <textarea
                                    className="request-form__input request-form__input--textarea"
                                    name="Vermogen"
                                    aria-label="Vermogen"
                                    required
                                    placeholder="Welk vermogen zoekt u voor de nieuwe hybride aggregaat in kVA?"
                                />
                                <button className="request-form__submit" aria-label="Verzenden" type="submit">
                                    Verzend
                                </button>
                            </form>
                        )}
                    </div>
                </div>
            </section>
        </main>
    );
};

export default GreenDeals;
