// Modules
import React from 'react';
import ImageGallery from 'react-image-gallery';

// Styling
import 'react-image-gallery/styles/css/image-gallery.css';

class Gallery extends React.Component {
    render() {
        const images = [
            {
                original: 'https://lorempixel.com/1000/600/nature/1/',
                thumbnail: 'https://lorempixel.com/250/150/nature/1/',
            },
            {
                original: 'https://lorempixel.com/1000/600/nature/2/',
                thumbnail: 'https://lorempixel.com/250/150/nature/2/',
            },
            {
                original: 'https://lorempixel.com/1000/600/nature/3/',
                thumbnail: 'https://lorempixel.com/250/150/nature/3/',
            },
        ];

        console.log(this.props);

        return (
            <ImageGallery
                onClick={event => console.log('onClick --> ', event)}
                onSlide={currentIndex => {
                    console.log('onSlide --> ', currentIndex);
                    this.props.navigateToAction(currentIndex);
                }}
                showNav={false}
                showBullets={true}
                thumbnailPosition={'right'}
                infinite={true}
                showPlayButton={false}
                showGalleryPlayButton={false}
                startIndex={this.props.startIndex}
                items={this.props.items || images}
            />
        );
    }
}

export default Gallery;
