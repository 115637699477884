// Modules
import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { NavLink } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import i18n from 'i18n-react';

// Styling
import './Header.scss';
import './HeaderPage.scss';
import './HeaderMobile.scss';

// Images
import logoHeaderSVG from '../img/the-green-generators-logo-buy-a-hybride-generator.svg';
import WASVG from '../img/whatsapp.svg';
import hamburger from '../img/hamburgermenu.svg';
import Johan_de_Leeuw from '../img/contact/Johan_de_Leeuw.jpg';
import Ryan_Colle from '../img/contact/Ryan-Colle2.jpg';

// Constants
import { modelsRoutes, navigationRoutes } from './constants';
import LanguageSelector from './LanguageSelector/LanguageSelector';

const StelEenVraag = props => (
    <button className="page-header__button-question page-header__button-question--invisible site-button" type="button" onClick={props.onClick}>
        {i18n.translate('askQuestionButton')}
    </button>
);

const LogoHomePage = () => (
    <NavLink className="page-header__logo" to="/">
        <img className="page-header__logo-image" src={logoHeaderSVG} width="230" height="80" alt="The Green Generators logo" />
    </NavLink>
);

const NavItem = ({ route }) => (
    <li className={`main-nav__list-item ${route.classBem}`}>
        <NavLink className="site-link" to={route.uri}>
            {i18n.translate(route.label)}
        </NavLink>
    </li>
);

const ModelsDropDownMenu = props => (
    <div className="dropdown">
        <button
            className={cx('dropbtn', {
                'dropbtn--index': props.isIndexRoute,
            })}
        >
            {i18n.translate('menuModels')}
        </button>
        <div className="dropdown-content">
            {modelsRoutes.map((route, key) => (
                <NavLink key={key} className="site-link" to={route.uri}>
                    {i18n.translate(route.label)}
                </NavLink>
            ))}
        </div>
    </div>
);

export class MyModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            spinner: false,
            formComplete: false,
        };
    }

    requestComplete = response => {
        this.setState({ spinner: false, formComplete: true });
        console.log(JSON.parse(response));
    };

    handleSubmit = e => {
        const { requestComplete } = this;
        e.preventDefault();
        const formData = new FormData();

        formData.set('Naam', e.target.Naam.value);
        formData.set('Bedrijf', e.target.Bedrijf.value);
        formData.set('Telefoonnummer', e.target.Telefoonnummer.value);
        formData.set('E-mailadres', e.target['E-mailadres'].value);
        formData.set('Bericht', e.target.Bericht.value);

        this.setState({ spinner: true });

        axios({
            method: 'post',
            url: 'https://thegreengenerators.com/phpmailer/contact-form.php',
            data: formData,
            config: { headers: { 'Content-Type': 'multipart/form-data' } },
        })
            .then(function(response) {
                //handle success
                console.log('SUCCESS!!!!!');
                return JSON.stringify(response);
            })
            .catch(function(response) {
                //handle error
                return JSON.stringify(response);
            })
            .then(requestComplete);
    };
    render() {
        return (
            <Modal backdrop={this.props.visibility} show={this.props.visibility} onHide={this.props.onClick}>
                <Modal.Body>
                    <div
                        className={cx('modal-question', {
                            'modal-question-open': this.props.visibility,
                            'modal-question-close': !this.props.visibility,
                        })}
                    >
                        <div className="bug-free-scroll">
                            <button
                                className="modal-question__button-close modal-question__button-close--invisible site-button button-reset"
                                type="button"
                                onClick={() => {
                                    this.setState({ spinner: false, formComplete: false });
                                    this.props.onClick();
                                }}
                            >
                                <i className={'material-icons'}>close</i>
                                <span>{i18n.translate('modalCloseButton')}</span>
                            </button>
                            <h2 className="modal-question__heading">
                                {this.props.title || i18n.translate(this.props.target === 'dealer-modal' ? 'modalDealerTitle' : 'modalQuestionTitle')}
                            </h2>
                            <div className="modal-question__wrapper">
                                <div className="contact-block">
                                    {this.props.showGreenTile && (
                                        <p className="modal-question__contact" onClick={() => window.open('tel:+31299416136')}>
                                            {i18n.translate('modalCallUs')}
                                        </p>
                                    )}{' '}
                                    <a style={{ float: 'left' }} target={'_blank'} href={'https://wa.me/31299416136'}>
                                        <img className={'wasvg'} src={WASVG} />
                                    </a>
                                </div>
                                <div className="modal-question__photo">
                                    <img src={this.props.salesImage ? Ryan_Colle : Ryan_Colle} width="200" height="200" alt="Foto van een medewerker" />
                                </div>
                            </div>
                            {this.state.formComplete ? (
                                <p style={{ marginTop: '35px', fontWeight: 'bold', backgroundColor: '#B8E4C9', padding: '15px 5px ' }}>{i18n.translate('modalThanksForMessage')}</p>
                            ) : this.state.spinner ? (
                                <p>Gegevens verzenden...</p>
                            ) : (
                                <form
                                    className="question-form"
                                    // action="https://thegreengenerators.com/phpmailer/contact-form.php"
                                    method="post"
                                    onSubmit={this.handleSubmit}
                                >
                                    <p className="question-form__intro">{i18n.translate('modalUseThisForm')}</p>

                                    <div className="form-container">
                                        <input type="text" required name="Naam" placeholder={i18n.translate('modalFieldName')} />
                                        <input type="text" required name="Bedrijf" placeholder={i18n.translate('modalFieldCompany')} />
                                        <input type="tel" required name="Telefoonnummer" placeholder={i18n.translate('modalFieldTel')} />
                                        <input type="email" required name="E-mailadres" placeholder={i18n.translate('modalFieldEmail')} />
                                        <textarea name="Bericht" placeholder={i18n.translate('modalFieldMessage')} />
                                        <button type="submit"> {i18n.translate('modalSendButton')}</button>
                                    </div>
                                </form>
                            )}
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

// Hook
function useWindowSize() {
    const isClient = typeof window === 'object';

    function getSize() {
        return {
            width: isClient ? window.innerWidth : undefined,
            height: isClient ? window.innerHeight : undefined,
        };
    }

    const [windowSize, setWindowSize] = useState(getSize);

    useEffect(() => {
        if (!isClient) {
            return false;
        }

        function handleResize() {
            setWindowSize(getSize());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []); // Empty array ensures that effect is only run on mount and unmount

    return windowSize;
}

const Header = () => {
    const isIndexRoute = window.location.pathname === '/';

    const [visibility, setVisibility] = useState(false);
    const [menuMobile, setMenuMobile] = useState(false);
    const [target, setTarget] = useState(false);

    // Make sure the mobile menu closes when the location is different
    if (window.location.href !== window.cachedHref) {
        setMenuMobile(false);
        window.cachedHref = window.location.href;
    }

    // Use the nice useWindowSize hook
    const size = useWindowSize();

    // Add an effect that updates the state when moving the menu mobile open to desktop
    // so that it closes the menu
    useEffect(
        () => {
            if (size.width > 1050 && menuMobile) {
                setMenuMobile(false);
            }
        },
        [size.width]
    );

    return (
        <header className={'page-header'}>
            <MyModal showGreenTile={true} target={target} visibility={visibility} onClick={() => setVisibility(!visibility)} />

            <LogoHomePage />
            <nav className="main-nav">
                <button className="main-nav__toggle button-reset" type="button" aria-label="Menu openen">
                    <div className="main-nav__toggle-icon" onClick={() => setMenuMobile(!menuMobile)}>
                        <img className={'hamburger'} src={hamburger} alt={'Menu openen'} />
                    </div>
                </button>
                <div
                    className={cx('main-nav__wrapper', {
                        'main-nav__wrapper--mobile': menuMobile,
                    })}
                >
                    <ul className="main-nav__list site-list">
                        {menuMobile ? (
                            modelsRoutes.map((route, key) => <NavItem key={key} route={route} />)
                        ) : (
                            <li className="main-nav__list-item main-nav__list-item--models">
                                <ModelsDropDownMenu isIndexRoute={isIndexRoute} />
                            </li>
                        )}

                        {navigationRoutes.map((route, key) => {
                            if (route.uri === '/green-deals')
                                return (
                                    <li key={'dealerModal'} className={`main-nav__list-item ${route.classBem}`}>
                                        <a
                                            className="site-link"
                                            href={'/'}
                                            onClick={e => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                setVisibility(!visibility);
                                                setTarget('dealer-modal');
                                            }}
                                        >
                                            {i18n.translate(route.label)}
                                        </a>
                                    </li>
                                );
                            return <NavItem key={key} route={route} onClick={() => setMenuMobile(!menuMobile)} />;
                        })}
                    </ul>
                </div>
                <StelEenVraag
                    onClick={() => {
                        setTarget('question-modal');
                        setVisibility(!visibility);
                    }}
                />

                <LanguageSelector useLocalStorage={true} languages={['en', 'nl']} onChange={language => console.log('on change -> ', language)} />
            </nav>
        </header>
    );
};

export default Header;
