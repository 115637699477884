// Modules
import React from 'react';
import cx from 'classnames';
import i18n from 'i18n-react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';

// Assets
import factsheet from '../../factsheet-hybride-groene-aggregaat-verhuur.pdf';

// Images
// Model 30
import eenvoudig30 from '../../img/model30/model-30-de-groene-aggregaat-hybride-off-grid-energie-oplossing-eenvoudig-te-plaatsen.jpg';
import installatie30 from '../../img/model30/model-30-de-groene-aggregaat-hybride-off-grid-energie-oplossing-automatische-installatie.jpg';
import bediening30 from '../../img/model30/model-30-de-groene-aggregaat-hybride-off-grid-energie-oplossing-gemakkelijk-te-bedienen.jpg';
import aansluiting30 from '../../img/model30/model-30-de-groene-aggregaat-hybride-off-grid-energie-oplossing-gemakkelijk-aan-te-sluiten.jpg';
import actief30 from '../../img/model30/model-30-de-groene-aggregaat-hybride-off-grid-energie-oplossing-binnen-2-minuten-actief.jpg';

// Model 45
import eenvoudig45 from '../../img/model45/model-45-de-groene-aggregaat-hybride-off-grid-energie-oplossing-eenvoudig-te-plaatsen.jpg';
import installatie45 from '../../img/model45/model-45-de-groene-aggregaat-hybride-off-grid-energie-oplossing-automatische-installatie.jpg';
import bediening45 from '../../img/model45/model-45-de-groene-aggregaat-hybride-off-grid-energie-oplossing-gemakkelijk-te-bedienen.jpg';
import aansluiting45 from '../../img/model45/model-45-de-groene-aggregaat-hybride-off-grid-energie-oplossing-gemakkelijk-aan-te-sluiten.jpg';
import actief45 from '../../img/model45/model-45-de-groene-aggregaat-hybride-off-grid-energie-oplossing-binnen-2-minuten-actief.jpg';

// Model 100
import eenvoudig100 from '../../img/model100/model-100-de-groene-aggregaat-hybride-off-grid-energie-oplossing-eenvoudig-te-plaatsen-2.jpg';
import bediening100 from '../../img/model100/model-100-de-groene-aggregaat-hybride-off-grid-energie-oplossing-gemakkelijk-te-bedienen.jpg';
import aansluiting100 from '../../img/model100/model-100-de-groene-aggregaat-hybride-off-grid-energie-oplossing-gemakkelijk-aan-te-sluiten.jpg';
import actief100 from '../../img/model100/model-100-de-groene-aggregaat-hybride-off-grid-energie-oplossing-binnen-2-minuten-actief-2.jpg';

// Maatwerk
import maatwerk2 from '../../img/maatwerk/maatwerk2.jpg';
import maatwerk3 from '../../img/maatwerk/maatwerk3.jpg';
import maatwerk5 from '../../img/maatwerk/maatwerk5.jpg';
import maatwerk6 from '../../img/maatwerk/maatwerk6.jpg';
import maatwerk7 from '../../img/maatwerk/maatwerk7.jpg';

// Styling
import './Models.scss';

// Components
import Slick from '../../components/Slick';
import Footer from '../../components/Footer';
import Header, { MyModal } from '../../components/Header';
import MadeBy from '../../components/MadeBy';

class Models extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentShowcaseItem: 0,
            readMore: false,
            visibility: false,
        };
    }

    setVisibility = value => {
        this.setState({ visibility: value });
    };

    updateShowcase = () => {
        const nextItem = this.state.currentShowcaseItem + 1;

        const numLabels = getModelInfo(this.props.model, 'labels').length - 1;

        this.setState({
            currentShowcaseItem: nextItem > numLabels ? 0 : nextItem,
        });
    };

    componentDidMount() {
        this.interval = setInterval(this.updateShowcase, 4000);
    }

    stopInterval(model) {
        clearInterval(this.interval);
        this.setState({ currentShowcaseItem: model || 0 });
    }

    resetInterval() {
        clearInterval(this.interval);
        this.setState({ currentShowcaseItem: 0 });
        this.interval = setInterval(this.updateShowcase, 4000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        const { model, navigateToAction } = this.props;
        const { currentShowcaseItem } = this.state;

        const labels = getModelInfo(model, 'labels');

        let initialSlide;
        switch (model) {
            case '30':
                initialSlide = 0;
                break;
            case '45':
                initialSlide = 1;
                break;
            case '100':
                initialSlide = 2;
                break;
            case 'maatwerk':
                initialSlide = 3;
                break;
            default:
                initialSlide = 0;
                break;
        }

        return (
            <>
                {getModelInfo(model, 'meta')}
                <Header />
                <MyModal
                    showGreenTile={false}
                    title={i18n.translate('modalModelTitle') + ' ' + getModelInfo(model, 'name') + '?'}
                    visibility={this.state.visibility}
                    salesImage={true}
                    onClick={() => this.setVisibility(!this.state.visibility)}
                />
                <main className={cx('page-main', { maatwerk: model === 'maatwerk' })}>
                    <section className={'model-list-menu not-on-mobile'}>
                        <a
                            onClick={() => {
                                this.resetInterval();
                                navigateToAction(0);
                            }}
                            className={cx({ 'active-model': model === '30' })}
                        >
                            {i18n.translate('model30')}
                        </a>
                        <a
                            onClick={() => {
                                this.resetInterval();
                                navigateToAction(1);
                            }}
                            className={cx({ 'active-model': model === '45' })}
                        >
                            {i18n.translate('model45')}
                        </a>
                        <a
                            onClick={() => {
                                this.resetInterval();
                                navigateToAction(2);
                            }}
                            className={cx({ 'active-model': model === '100' })}
                        >
                            {i18n.translate('model100')}
                        </a>
                        <a
                            onClick={() => {
                                this.resetInterval();
                                navigateToAction(3);
                            }}
                            className={cx({ 'active-model': model === 'maatwerk' })}
                        >
                            {i18n.translate('custom')}
                        </a>
                    </section>
                    <section className={'model-list-menu not-on-desktop'}>
                        <Slick
                            initialSlide={initialSlide}
                            onAfterChange={index => {
                                this.resetInterval();
                                navigateToAction(index);
                            }}
                        />
                    </section>

                    <div className={'model-page-container'}>
                        <div className={'info-block'}>
                            <h2>{getModelInfo(model, 'title')}</h2>
                            <p>{getModelInfo(model, 'info_1')}</p>
                            <p>{getModelInfo(model, 'info_2')}</p>
                            <div className={'contact-info'}>
                                <h2> {model === 'maatwerk' ? i18n.translate('modelsCustomContactTitle') : i18n.translate('homeModelSelectorContactTitleMobile')}</h2>
                                <p>{model === 'maatwerk' ? i18n.translate('modelsCustomContactTitleSub') : i18n.translate('homeModelSelectorContactTitleSub')}</p>
                                <div className="comparison__contact-container--desktop">
                                    <div className="comparison__button contact-button contact-button--phone site-button" onClick={() => window.open('tel:+31299416136')}>
                                        <a className="site-link" href="tel:+31299416136" alt="Bel met de groene aggregaat" rel="noopener">
                                            +31 299 - 416136
                                        </a>
                                    </div>
                                    <div className="comparison__button contact-button contact-button--mail site-button" onClick={() => this.setVisibility(!this.state.visibility)}>
                                        <a className="site-link" alt="Stuur een bericht naar de groene aggregaat" rel="noopener">
                                            {i18n.translate('homeModelSelectorMessage')}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'showcase-block'}>
                            <div className={'desktop-labels'}>
                                <div className={cx('showcase-label', { 'active-label': currentShowcaseItem === 0 })} onClick={() => this.stopInterval(0)}>
                                    <div>{labels[0]}</div>
                                </div>
                                <div className={cx('showcase-label', { 'active-label': currentShowcaseItem === 1 })} onClick={() => this.stopInterval(1)}>
                                    <div>{labels[1]}</div>
                                </div>
                                <div className={cx('showcase-label', { 'active-label': currentShowcaseItem === 2 })} onClick={() => this.stopInterval(2)}>
                                    <div>{labels[2]}</div>
                                </div>
                                {labels[3] && (
                                    <div className={cx('showcase-label', { 'active-label': currentShowcaseItem === 3 })} onClick={() => this.stopInterval(3)}>
                                        <div>{labels[3]}</div>
                                    </div>
                                )}
                                {labels[4] && (
                                    <div className={cx('showcase-label', { 'active-label': currentShowcaseItem === 4 })} onClick={() => this.stopInterval(4)}>
                                        <div>{labels[4]}</div>
                                    </div>
                                )}
                            </div>

                            <div className={'showcase-image-container'}>
                                <img className={'showcase-image'} alt={'model'} src={getModelInfo(model, 'images')[currentShowcaseItem]} />

                                <div className={'mobile-showcase-labels'}>
                                    <div className={'mobile-label'}>{labels[currentShowcaseItem]}</div>
                                    <div className={'mobile-label-dots'}>
                                        {labels.map((label, key) => (
                                            <div key={key} className={key === currentShowcaseItem ? 'active' : ''} />
                                        ))}
                                    </div>
                                </div>
                            </div>

                            <div className={'mobile-info-block'}>
                                <div className={this.state.readMore ? 'animate-readmore' : 'animate-readless'}>
                                    <p>{getModelInfo(model, 'info_1')}</p>
                                    <p>{getModelInfo(model, 'info_2')}</p>
                                </div>

                                <button className={'read-more'} onClick={() => this.setState({ readMore: !this.state.readMore })}>
                                    {i18n.translate('generalReadmoreRead')} {!this.state.readMore ? i18n.translate('generalReadmoreMore') : i18n.translate('generalReadmoreLess')}
                                </button>

                                <p className={'interesse'}>{model === 'maatwerk' ? i18n.translate('modelsCustomContactTitle') : i18n.translate('homeModelSelectorContactTitle')}</p>

                                <div className={'model-contact-buttons'}>
                                    <a
                                        style={{ border: '3px solid #009f3c' }}
                                        className="team-member__contact-mobile contact-button-mobile contact-button-mobile--phone"
                                        href="tel:+31299416136"
                                        alt="Bel met de groene aggregaat"
                                    >
                                        {i18n.translate('homeModelSelectorCallUsMobile')}{' '}
                                    </a>
                                    <a
                                        style={{ border: '3px solid #009f3c' }}
                                        className="team-member__contact-mobile contact-button-mobile contact-button-mobile--mail"
                                        alt="Stuur een bericht naar de groene aggragaat"
                                        onClick={() => this.setVisibility(!this.state.visibility)}
                                    >
                                        {i18n.translate('homeModelSelectorMessageMobile')}
                                    </a>
                                </div>
                            </div>

                            <div className={'model-specs'}>
                                <div className={'facts'}>
                                    <h2>
                                        {getModelInfo(model, 'name')} {i18n.translate('modelsSpecsBlockTitle')}{' '}
                                        <a className="download-button-factsheet" href={factsheet} alt="Download een pdf met alle gegevens" target={'_blank'} download>
                                            <i className={'material-icons'}>cloud_download</i>
                                            <div className={'specs-download'}>{i18n.translate('modelsSpecsButton')}</div>
                                        </a>{' '}
                                    </h2>
                                    <div className={'left-specs'}>
                                        <span>- {getModelInfo(model, 'specs')[0]}</span>
                                        <span>- {getModelInfo(model, 'specs')[1]}</span>
                                        <span>- {getModelInfo(model, 'specs')[2]}</span>
                                    </div>
                                    <div className={'right-specs'}>
                                        <span>- {getModelInfo(model, 'specs')[3]}</span>
                                        <span>- {getModelInfo(model, 'specs')[4]}</span>
                                        <span>- {getModelInfo(model, 'specs')[5]}</span>
                                    </div>
                                </div>

                                <div className={'weekly-savings'}>
                                    <h3>{i18n.translate('modelsSavingsTitle')}</h3>
                                    <p>{i18n.translate('modelsSavingsText1')}</p>
                                    <p>
                                        {i18n.translate('modelsSavingsText2')} {getModelInfo(model, 'fuel')}
                                    </p>
                                    <p>
                                        {i18n.translate('modelsSavingsText3')} {getModelInfo(model, 'co2')}
                                    </p>
                                </div>
                            </div>

                            <div className={'mobile-model-specs'}>
                                <h2>
                                    {getModelInfo(model, 'name')} {i18n.translate('modelsSpecsButton')}{' '}
                                </h2>
                                <div>
                                    <span>{getModelInfo(model, 'specs')[0]}</span>
                                    <span>{getModelInfo(model, 'specs')[1]}</span>
                                    <span>{getModelInfo(model, 'specs')[2]}</span>
                                    <span>{getModelInfo(model, 'specs')[3]}</span>
                                    <span>{getModelInfo(model, 'specs')[4]}</span>
                                    <span>{getModelInfo(model, 'specs')[5]}</span>
                                </div>
                                <h3>{i18n.translate('modelsSavingsTitle')}</h3>
                                <p>{i18n.translate('modelsSavingsText1')}</p>
                                <p>
                                    {i18n.translate('modelsSavingsText2')} {getModelInfo(model, 'fuel')}
                                </p>
                                <p>
                                    {i18n.translate('modelsSavingsText3')} {getModelInfo(model, 'co2')}
                                </p>
                                <a className={'download-button-factsheet'} href={factsheet} alt="Download een pdf met alle gegevens" target={'_blank'} download>
                                    <i className={'material-icons'}>cloud_download</i>
                                    <div className={'specs-download'}>{i18n.translate('modelsSpecsButton')}</div>
                                </a>{' '}
                            </div>
                        </div>
                    </div>
                </main>
                <Footer />
                <MadeBy />
            </>
        );
    }
}

function mapStateToProps(state, props) {
    return { routeProps: props };
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    navigateToAction: index => {
        let url = '/';
        // switch the index intpo the currect model number -->
        switch (index) {
            case 0:
                url = '/model-30';
                break;
            case 1:
                url = '/model-45';
                break;
            case 2:
                url = '/model-100';
                break;
            case 3:
                url = '/custom';
                break;
            default:
                break;
        }
        console.log('probeer te navigeren naar model --> ', index, ownProps);
        ownProps.history.push(url);
    },
});

const mergeProps = (stateProps, dispatchProps, ownprops) => {
    console.log('stateProps --> ', stateProps);
    let model = stateProps.routeProps.match.path.split('/model-')[1];
    if (!model) {
        model = 'maatwerk';
    }
    return {
        ...stateProps,
        ...dispatchProps,
        model,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(Models);

function getModelInfo(modelId, prop) {
    const modelsInfo = {
        '30': {
            name: i18n.translate('model30'),
            title: i18n.translate('model30'),
            info_1: i18n.translate('homeModel30Text'),
            info_2: i18n.translate('modelsDefaultText'),
            labels: [i18n.translate('modelLabel1'), i18n.translate('modelLabel2'), i18n.translate('modelLabel3'), i18n.translate('modelLabel4'), i18n.translate('modelLabel5')],
            images: [eenvoudig30, installatie30, bediening30, aansluiting30, actief30],
            specs: [
                i18n.translate('models30Spec1'),
                i18n.translate('models30Spec2'),
                i18n.translate('models30Spec3'),
                i18n.translate('models30Spec4'),
                i18n.translate('models30Spec5'),
                i18n.translate('models30Spec6'),
            ],
            fuel: i18n.translate('modelsSavingsFuelModel30'),
            co2: i18n.translate('modelsSavingsCO2Model30'),
            meta: (
                <Helmet>
                    <title>{i18n.translate('metaModel30Title')}</title>
                    <meta charSet="utf-8" />
                    <meta
                        name="description"
                        content={i18n.translate('metaModel30Description')}
                    />
                </Helmet>
            ),
        },
        '45': {
            name: i18n.translate('model45'),
            title: i18n.translate('model45'),
            info_1: i18n.translate('homeModel45Text'),
            info_2: i18n.translate('modelsDefaultText'),
            labels: [i18n.translate('modelLabel1'), i18n.translate('modelLabel2'), i18n.translate('modelLabel3'), i18n.translate('modelLabel4'), i18n.translate('modelLabel5')],
            images: [eenvoudig45, installatie45, bediening45, aansluiting45, actief45],
            specs: [
                i18n.translate('models45Spec1'),
                i18n.translate('models45Spec2'),
                i18n.translate('models45Spec3'),
                i18n.translate('models45Spec4'),
                i18n.translate('models45Spec5'),
                i18n.translate('models45Spec6'),
            ],
            fuel: i18n.translate('modelsSavingsFuelModel45'),
            co2: i18n.translate('modelsSavingsCO2Model45'),
            meta: (
                <Helmet>
                    <title>{i18n.translate('metaModel45Title')}</title>
                    <meta charSet="utf-8" />
                    <meta
                        name="description"
                        content={i18n.translate('metaModel45Description')}
                    />
                </Helmet>
            ),
        },
        '100': {
            name: i18n.translate('model100'),
            title: i18n.translate('model100'),
            info_1: i18n.translate('homeModel100Text'),
            info_2: i18n.translate('modelsDefaultText'),
            labels: [i18n.translate('modelLabel1'), i18n.translate('modelLabel3'), i18n.translate('modelLabel4'), i18n.translate('modelLabel5')],
            images: [eenvoudig100, bediening100, aansluiting100, actief100],
            specs: [
                i18n.translate('models100Spec1'),
                i18n.translate('models100Spec2'),
                i18n.translate('models100Spec3'),
                i18n.translate('models100Spec4'),
                i18n.translate('models100Spec5'),
                i18n.translate('models100Spec6'),
            ],
            fuel: i18n.translate('modelsSavingsFuelModel100'),
            co2: i18n.translate('modelsSavingsCO2Model100'),
            meta: (
                <Helmet>
                    <title>{i18n.translate('metaModel100Title')}</title>
                    <meta charSet="utf-8" />
                    <meta
                        name="description"
                        content={i18n.translate('metaModel100Description')}
                    />
                </Helmet>
            ),
        },
        maatwerk: {
            name:  i18n.translate('custom'),
            title: i18n.translate('custom'),
            info_1: i18n.translate('modelsCustomText1'),
            info_2: i18n.translate('modelsCustomText2'),
            labels: [
                i18n.translate('modelLabelCustom1'),
                i18n.translate('modelLabelCustom2'),
                i18n.translate('modelLabelCustom3'),
                i18n.translate('modelLabelCustom4'),
                i18n.translate('modelLabelCustom5'),
            ],
            images: [maatwerk7, maatwerk5, maatwerk6, maatwerk2, maatwerk3],
            specs: [
                i18n.translate('modelsCustomSpec1'),
                i18n.translate('modelsCustomSpec2'),
                i18n.translate('modelsCustomSpec3'),
                i18n.translate('modelsCustomSpec4'),
                i18n.translate('modelsCustomSpec5'),
                i18n.translate('modelsCustomSpec6'),
            ],
            fuel: i18n.translate('modelsSavingsFuelCustom'),
            co2: i18n.translate('modelsSavingsCO2Custom'),
            meta: (
                <Helmet>
                    <title>{i18n.translate('metaCustomTitle')}</title>
                    <meta charSet="utf-8" />
                    <meta
                        name="description"
                        content={i18n.translate('metaCustomDescription')}
                    />
                </Helmet>
            ),
        },
    };

    return modelsInfo[modelId][prop];
}
