export const modelsRoutes = [
    // { uri: "/model-5", label: "menuModel5" },
    { uri: "/model-30", label: "menuModel30" },
    { uri: "/model-45", label: "menuModel45" },
    {
        uri: "/model-100",
        label: "menuModel100"
    }
];

export const navigationRoutes = [
    {
        uri: "/custom",
        label: "menuCustom",
        classBem: "main-nav__list-item--custom"
    },
    {
        uri: "/green-deals",
        label: "menuDealers",
        classBem: "main-nav__list-item--custom"
    },
    {
        uri: "/projects",
        label: "menuProjects",
        classBem: "main-nav__list-item--action"
    },
    {
        uri: "/our-vision",
        label: "menuVision",
        classBem: "main-nav__list-item--vision"
    },
    {
        uri: "/contact",
        label: "menuContact",
        classBem: "main-nav__list-item--contact"
    }
];
