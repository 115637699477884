// Modules
import React from 'react';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';
import { connect } from 'react-redux';
import Slider from 'react-slick';
import i18n from 'i18n-react';

// Images
import model30 from '../../img/home-models/model-30/huur-onze-groene-aggregaat-model-30-de-hybride-besparende-aggregaat-met-zonnepanelen-klein.png';
import model45 from '../../img/home-models/model-45/huur-onze-groene-aggregaat-model-45-bespaar-op-milileu-en-brandstof-met-de-hybride-stroom-oplossing-klein.png';
import model100 from '../../img/home-models/model-100/huur-onze-groene-aggregaat-model-100-hybride-aggregaat-met-zonnepanelen-veel-vermogen-en-laag-co2-uitstoot-klein.png';

import logoInruilSVGMobile from '../../img/logo-groene-inruildeals.svg';
import logoInruilSVG from '../../img/logo groene inruildeals.svg';

// Components
import Footer from '../../components/Footer';
import Header, { MyModal } from '../../components/Header';
import { Desktop, Mobile, Tablet } from '../../components/Wrappers';
import { OfferteModal } from '../green-deals/GreenDeals';

// Styling
import './advantages-list.scss';
import './advantages-list-mobile.scss';
import './body.scss';
import './comparison.scss';
import './comparison-mobile.scss';
import './exchange.scss';
import './exchange-mobile.scss';
import './explore.scss';
import './explore-mobile.scss';
import './intro.scss';
import './intro-mobile.scss';
import './modal-question.scss';
import '../../components/Slick.scss';
import MadeBy from '../../components/MadeBy';

class Home extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            currentModelIndex: 2,
            modalVisible: false,
            visibility: false,
            target: 'default',
        };
    }

    getModelInfoByIndex = (index, property) => {
        return {
            '0': {
                name: 'Model 30',
                route: '/model-30',
                fuel: { min: i18n.translate('homeModel30CostsGreen'), max: i18n.translate('homeModel30CostsRed') },
                co2: { min: i18n.translate('homeModel30CO2Green'), max: i18n.translate('homeModel30CO2Red') },
                shortInfo: i18n.translate('homeModel30Text'),
            },
            '1': {
                name: 'Model 45',
                route: '/model-45',
                fuel: { min: i18n.translate('homeModel45CostsGreen'), max: i18n.translate('homeModel45CostsRed') },
                co2: { min: i18n.translate('homeModel45CO2Green'), max: i18n.translate('homeModel45CO2Red') },
                shortInfo: i18n.translate('homeModel45Text'),
            },
            '2': {
                name: 'Model 100',
                route: '/model-100',
                fuel: { min: i18n.translate('homeModel100CostsGreen'), max: i18n.translate('homeModel100CostsRed') },
                co2: { min: i18n.translate('homeModel100CO2Green'), max: i18n.translate('homeModel100CO2Red') },
                shortInfo: i18n.translate('homeModel100Text'),
            },
        }[index][property];
    };

    onScrollToComparison = e => {
        // Get the element we want to scroll to form DOM
        const exploreElement = document.getElementsByClassName('explore')[0];

        if (exploreElement) {
            window.scrollTo({
                top: exploreElement.offsetTop - 20,
                behavior: 'smooth',
            });
        }
    };

    setActiveModel = currentModelIndex => {
        console.log('currentModelIndex --> ', currentModelIndex);
        this.setState({ currentModelIndex });

        const outerFuel = document.getElementsByClassName('comparison__bar--outer-fuel')[0];
        const innerFuel = document.getElementsByClassName('comparison__bar-inner--fuel')[0];
        const outerCO2 = document.getElementsByClassName('comparison__bar--outer-co2')[0];
        const innerCO2 = document.getElementsByClassName('comparison__bar-inner--co2')[0];

        if (outerFuel && innerFuel && outerCO2 && innerCO2) {
            if (outerFuel.classList.contains('animate')) {
                outerFuel.classList.remove('animate');
                innerFuel.classList.remove('animate');
                outerCO2.classList.remove('animate');
                innerCO2.classList.remove('animate');
            }

            setTimeout(() => {
                outerFuel.classList.add('animate');
                innerFuel.classList.add('animate');
                outerCO2.classList.add('animate');
                innerCO2.classList.add('animate');
            }, 1);
        }
    };

    updateDimensions = () => {
        if (window.innerWidth >= 750) {
            this.setState({ width: window.innerWidth, height: window.innerHeight });
        }
    };

    onScroll = () => {
        const element = document.getElementsByClassName('intro__next')[0];
        if (element) {
            element.style.opacity = 1 - window.scrollY / 250;
        }
        const outerFuel = document.getElementsByClassName('comparison__bar--outer-fuel')[0];
        const innerFuel = document.getElementsByClassName('comparison__bar-inner--fuel')[0];
        const outerCO2 = document.getElementsByClassName('comparison__bar--outer-co2')[0];
        const innerCO2 = document.getElementsByClassName('comparison__bar-inner--co2')[0];

        // Animate the progress bars
        if (window.scrollY > 610) {
            if (outerFuel && innerFuel && outerCO2 && innerCO2) {
                if (!outerFuel.classList.contains('animate')) {
                    outerFuel.classList.add('animate');
                    innerFuel.classList.add('animate');
                    outerCO2.classList.add('animate');
                    innerCO2.classList.add('animate');
                }
            }
        } else {
            if (outerFuel && innerFuel && outerCO2 && innerCO2) {
                if (outerFuel.classList.contains('animate')) {
                    outerFuel.classList.remove('animate');
                    innerFuel.classList.remove('animate');
                    outerCO2.classList.remove('animate');
                    innerCO2.classList.remove('animate');
                }
            }
        }
    };

    componentDidMount() {
        window.addEventListener('scroll', this.onScroll);
        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
        console.log('ik unmount nu');
        window.removeEventListener('resize', this.updateDimensions);
        window.removeEventListener('scroll', this.onScroll);
    }

    onBannerClick = () => {
        this.setState({ target: 'dealer-modal' });
        this.setVisibility(true);
    };

    setVisibility = value => {
        this.setState({ visibility: value });
    };

    render() {
        return (
            <>
                <Header />
                <MyModal
                    showGreenTile={false}
                    title={
                        this.state.target === 'default' ? i18n.translate('modalModelTitle') + ' ' + this.getModelInfoByIndex(this.state.currentModelIndex, 'name') + '?' : undefined
                    }
                    visibility={this.state.visibility}
                    salesImage={true}
                    target={this.state.target}
                    onClick={() => this.setVisibility(!this.state.visibility)}
                />
                <main className="page-main page-home">
                    <h1 className="visually-hidden">De Groene Aggregaat</h1>
                    <div className="background-image" style={{ minHeight: this.state.height || window.innerHeight, height: this.state.height || window.innerHeight }} />
                    <section className="intro" style={{ minHeight: this.state.height || window.innerHeight, height: this.state.height || window.innerHeight }}>
                        <h2 className="intro__heading">{i18n.translate('heroSlogan')}</h2>
                        <p className="intro__subheading">{i18n.translate('heroSubslogan')} </p>
                        <h2 className="intro__heading-mobile">{i18n.translate('heroArrowText')}</h2>
                        <button className="intro__next button-reset" type="button" aria-label="Verder lezen" onClick={this.onScrollToComparison} />
                    </section>

                    <section className="explore">
                        <div className="explore__wrapper">
                            <h2 className="explore__heading-desktop">{i18n.translate('homeDiscoverTitle')}</h2>
                            <p className="explore__subheading">{i18n.translate('homeDiscoverSubTitle')} </p>

                            <Mobile>
                                <div className="container">
                                    <Slider
                                        slidesToShow={1}
                                        initialSlide={this.state.currentModelIndex}
                                        afterChange={args => {
                                            this.setActiveModel(args);
                                        }}
                                        onSwipe={() => console.log('Finger is not on screen anymore --> ')}
                                        {...{
                                            dots: false,
                                        }}
                                    >
                                        <div className={'model-image-wrapper model-30'}>
                                            <img src={model30} alt="Model 30" />
                                        </div>
                                        <div className={'model-image-wrapper model-45'}>
                                            <img src={model45} alt="Model 45" />
                                        </div>
                                        <div className={'model-image-wrapper model-100'}>
                                            <img src={model100} alt="Model 100" />
                                        </div>
                                    </Slider>
                                </div>
                                <h2 className="comparison__heading">{this.getModelInfoByIndex(this.state.currentModelIndex, 'name')}</h2>
                            </Mobile>

                            <Tablet>
                                <div className="container">
                                    <Slider
                                        slidesToShow={1}
                                        initialSlide={this.state.currentModelIndex}
                                        afterChange={args => {
                                            this.setActiveModel(args);
                                        }}
                                        onSwipe={() => console.log('Finger is not on screen anymore --> ')}
                                        {...{
                                            dots: false,
                                        }}
                                    >
                                        <div className={'model-image-wrapper model-30'}>
                                            <img src={model30} alt="Model 30" />
                                        </div>
                                        <div className={'model-image-wrapper model-45'}>
                                            <img src={model45} alt="Model 45" />
                                        </div>
                                        <div className={'model-image-wrapper model-100'}>
                                            <img src={model100} alt="Model 100" />
                                        </div>
                                    </Slider>
                                </div>
                                <h2 className="comparison__heading">{this.getModelInfoByIndex(this.state.currentModelIndex, 'name')}</h2>
                            </Tablet>

                            <Desktop>
                                <ul className="model-list site-list">
                                    <li
                                        className={cx('model-list__item', {
                                            'model-list__item--active': this.state.currentModelIndex === 0,
                                        })}
                                        onClick={() => this.setActiveModel(0)}
                                    >
                                        <img className="model-list__image" src={model30} style={{ width: '200px', height: 'auto' }} alt="Model 30" />
                                        <div className="model-list__wrapper">
                                            <a className="model-list__link site-link">Model 30</a>
                                        </div>
                                    </li>
                                    <li
                                        className={cx('model-list__item', {
                                            'model-list__item--active': this.state.currentModelIndex === 1,
                                        })}
                                        onClick={() => this.setActiveModel(1)}
                                    >
                                        <img src={model45} style={{ width: '230px', height: 'auto' }} alt="Model 45" />
                                        <div className="model-list__wrapper">
                                            <a className="model-list__link site-link">Model 45</a>
                                        </div>
                                    </li>
                                    <li
                                        className={cx('model-list__item', {
                                            'model-list__item--active': this.state.currentModelIndex === 2,
                                        })}
                                        onClick={() => this.setActiveModel(2)}
                                    >
                                        <img src={model100} style={{ width: '430px', height: 'auto' }} alt="Model 100" />
                                        <div className="model-list__wrapper">
                                            <a className="model-list__link site-link">Model 100</a>
                                        </div>
                                    </li>
                                </ul>
                            </Desktop>

                            <div className="comparison">
                                <div className="comparison__model">
                                    <h3 className="comparison__heading comparison__heading--hidden">{this.getModelInfoByIndex(this.state.currentModelIndex, 'name')}</h3>
                                    <p className="comparison__description">{this.getModelInfoByIndex(this.state.currentModelIndex, 'shortInfo')}</p>
                                    <NavLink
                                        className="comparison__read-more comparison__read-more--desktop site-link"
                                        to={this.getModelInfoByIndex(this.state.currentModelIndex, 'route')}
                                    >
                                        {i18n.translate('homeModelSelectorReadMore')}
                                    </NavLink>
                                </div>
                                <div className="comparison__graph">
                                    <div className="comparison__wrapper">
                                        <h3 className="comparison__heading comparison__heading--inline-block">{i18n.translate('homePossibleSaving')}</h3>
                                        <NavLink
                                            className="comparison__read-more comparison__read-more--mobile  site-link"
                                            to={this.getModelInfoByIndex(this.state.currentModelIndex, 'route')}
                                        >
                                            {i18n.translate('homeModelSelectorReadMoreMobile')}
                                        </NavLink>
                                    </div>
                                    <div className={'colorful-text'}>
                                        <span className="comparison__versus comparison__versus--green">{i18n.translate('homeVersusThegreengenerator')}</span>
                                        <span className="comparison__versus comparison__versus--regular">{i18n.translate('homeVersus')}</span>
                                        <span className="comparison__versus comparison__versus--red">{i18n.translate('homeVersusConventional')}</span>
                                    </div>

                                    {/*<br />*/}

                                    <div className="animation-box animation-box--top">
                                        <p className="comparison__title">{i18n.translate('homeFuelCostsYear')}</p>
                                        <div className="comparison__bar comparison__bar--outer-fuel">
                                            <div className="comparison__bar-inner comparison__bar-inner--fuel" />

                                            <div className="comparison__number-container comparison__number-container--fuel">
                                                <div className="comparison__number comparison__number--small">
                                                    {this.getModelInfoByIndex(this.state.currentModelIndex, 'fuel').min}
                                                </div>
                                                <div className="comparison__number comparison__number--big">
                                                    {this.getModelInfoByIndex(this.state.currentModelIndex, 'fuel').max}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/*<br />*/}

                                    <div className="animation-box">
                                        <p className="comparison__title">{i18n.translate('homeCO2EmissionsYear')}</p>
                                        <div className="comparison__bar comparison__bar--outer-co2">
                                            <div className="comparison__bar-inner comparison__bar-inner--co2" />

                                            <div className="comparison__number-container comparison__number-container--co2">
                                                <div className="comparison__number comparison__number--small">
                                                    {this.getModelInfoByIndex(this.state.currentModelIndex, 'co2').min}
                                                </div>
                                                <div className="comparison__number comparison__number--big">
                                                    {this.getModelInfoByIndex(this.state.currentModelIndex, 'co2').max}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="rent">
                                    <h3 className="comparison__heading comparison__heading--rent">{i18n.translate('homeModelSelectorContactTitle')}</h3>
                                    <p className="comparison__subheading comparison__subheading--desktop">{i18n.translate('homeModelSelectorContactTitleSub')}</p>
                                    <h3 className="comparison__heading--mobile">{i18n.translate('homeModelSelectorContactTitleMobile')}</h3>
                                    <div className="comparison__contact-container--desktop">
                                        <div className="comparison__button contact-button contact-button--phone site-button" onClick={() => window.open('tel:+31299416136')}>
                                            <a className="site-link" href="tel:+31299416136">
                                                +31 299 416136
                                            </a>
                                        </div>
                                        <div
                                            className="comparison__button contact-button contact-button--mail site-button"
                                            onClick={e => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                this.setState({ target: 'default' });
                                                this.setVisibility(!this.state.visibility);
                                            }}
                                        >
                                            {i18n.translate('homeModelSelectorMessage')}
                                        </div>
                                    </div>
                                    <div className="comparison__contact-container--mobile">
                                        <div className="contact-button-mobile contact-button-mobile--phone site-button" onClick={() => window.open('tel:+31299416136')}>
                                            <a className="site-link" href="tel:+31299416136">
                                                {i18n.translate('homeModelSelectorCallUsMobile')}
                                            </a>
                                        </div>
                                        <div className="contact-button-mobile contact-button-mobile--mail site-button" onClick={() => this.setVisibility(!this.state.visibility)}>
                                            <a className="site-link">{i18n.translate('homeModelSelectorMessageMobile')}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <section className="explore why-us">
                                <div className="explore__wrapper">
                                    <h2 className="explore__heading-desktop">{i18n.translate('homeAboutTextTitle')}</h2>
                                    <p className="explore__subheading about-us">{i18n.translate('homeAboutText')}</p>
                                    <p className="explore__subheading about-us">{i18n.translate('homeAboutText2')}</p>
                                </div>
                            </section>

                            <ul className="advantages-list">
                                <li className="advantages-list__item advantages-list__item--profitable">
                                    <div className="advantages-list__item-box">
                                        <div className="advantages-list__item-icon" />
                                        <div className="advantages-list__item-container advantages-list__item-container--profitable">
                                            <h3 className="advantages-list__item-title advantages-list__item-title">{i18n.translate('iconProfitable')}</h3>
                                            <p className="advantages-list__item-description">{i18n.translate('iconProfitableSub')}</p>
                                        </div>
                                    </div>
                                </li>
                                <li className="advantages-list__item advantages-list__item--reduction">
                                    <div className="advantages-list__item-box">
                                        <div className="advantages-list__item-icon" />
                                        <div className="advantages-list__item-container advantages-list__item-container--reduction">
                                            <h3 className="advantages-list__item-title">{i18n.translate('iconCO2')}</h3>
                                            <p className="advantages-list__item-description">{i18n.translate('iconCO2Sub')}</p>
                                        </div>
                                    </div>
                                </li>
                                <li className="advantages-list__item  advantages-list__item--easy">
                                    <div className="advantages-list__item-box">
                                        <div className="advantages-list__item-icon" />
                                        <div className="advantages-list__item-container advantages-list__item-container--easy">
                                            <h3 className="advantages-list__item-title">{i18n.translate('iconEasyToUse')}</h3>
                                            <p className="advantages-list__item-description">{i18n.translate('iconEasyToUseSub')}</p>
                                        </div>
                                    </div>
                                </li>
                                <li className="advantages-list__item advantages-list__item--silent">
                                    <div className="advantages-list__item-box">
                                        <div className="advantages-list__item-icon" />
                                        <div className="advantages-list__item-container advantages-list__item-container--silent">
                                            <h3 className="advantages-list__item-title">{i18n.translate('iconLowNoise')}</h3>
                                            <p className="advantages-list__item-description">{i18n.translate('iconLowNoiseSub')}</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div className="exchange" onClick={this.onBannerClick}>
                            <div className="exchange__wrapper">
                                <div className="exchange__description-wrapper">
                                    <p>{i18n.translate('homeBannerTitle')}</p>
                                </div>

                                <div className={'mobile-dealer'}>
                                    <h2>{i18n.translate('homeBannerTitleMobile')}</h2>
                                    <p>{i18n.translate('homeBannerText1')}</p>
                                    <p>{i18n.translate('homeBannerText2')}</p>
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                </div>

                                <div className="exchange__link-mobile">Become a dealer</div>
                            </div>
                        </div>
                    </section>
                </main>
                <Footer />
                <MadeBy />
            </>
        );
    }
}

function mapStateToProps(state, props) {
    return { routeProps: props };
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    navigateToGreenDealsAction: index => {
        console.log('probeer te navigeren --> ', index, ownProps);
        ownProps.history.push('/green-deals');
    },
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Home);
