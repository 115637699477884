// Modules
import React from 'react'
import { withRouter } from 'react-router'

class ScrollToTop extends React.Component {
    componentDidUpdate(prevProps) {
        const { location } = this.props
        const { location: prevLocation } = prevProps

        // Do not reset scroll to top when using the slider -->
        if (location.pathname.includes('/in-actie/') && prevLocation.pathname.includes('/in-actie/')) {
            return
        }

        if (location !== prevLocation) {
            window.scrollTo(0, 0)
        }
    }

    render() {
        return this.props.children
    }
}

export default withRouter(ScrollToTop)
