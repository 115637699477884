// Modules
import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";

/**
 * A Basic Reducer Function
 * Reducers are functions that update the state / store with new data
 * @param state
 * @param action
 * @returns {*}
 */
function dataStateReducer(state, action) {
    // Make a shallow copy of state
    return { ...state };

    //let { payload, dataType } = action;
    // return newState;
}

/**
 * Export all reducers of the application as 1 combined reducers object
 * @type {Reducer<any> | Reducer<any, AnyAction>}
 */
export const reducers = combineReducers({
    routing: routerReducer,
    data: dataStateReducer,
    components: {},
});
