import React from "react";

// Styling
import "./Wrappers.scss";
/**
 * Resolutions will be specified inside the scss file
 */

// Wrapper component that will render only on Desktop resolutions
export const Desktop = props => (
    <div className={"desktop-design"}>{props.children}</div>
);

// Wrapper component that will render only on Tablet resolutions
export const Tablet = props => (
    <div className={"tablet-design"}>{props.children}</div>
);

// Wrapper component that will render only on Mobile resolutions
export const Mobile = props => (
    <div className={"mobile-design"}>{props.children}</div>
);
