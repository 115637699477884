import React, { Fragment } from 'react';
import Helmet from 'react-helmet';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import T from 'i18n-react';

// Reducers
import { reducers } from './reducers';

// Saga
import { rootSaga } from './sagas';

// import logo from "./logo.svg";
import './App.scss';

// Translations
import nl from './nl';
import en from './en';

// Components
import Page from './components/Page';
import Home from './routes/home/Home';
import Contact from './routes/contact/Contact';
import GreenDeals from './routes/green-deals/GreenDeals';
import Models from './routes/model/Models';
import ScrollToTop from './components/ScrollToTop';
import OnzeVisie from './routes/onze-visie/OnzeVisie';
import PageNotFound from './routes/404/PageNotFound';
import InActie from './routes/in-actie/InActie';

function App() {
    // 1) Create and Apply the redux saga Middlewares
    const sagaMiddleware = createSagaMiddleware();
    // const _routerMiddleware = routerMiddleware();

    // 2) Create the initial redux state of the application
    const initialState = {};

    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    // 3) Create the Redux Store and apply the saga middleware to it
    const store = createStore(reducers, initialState, composeEnhancers(applyMiddleware(sagaMiddleware)));

    // 4) Start the root saga
    sagaMiddleware.run(rootSaga);

    // Set Texts
    const defaultLanguage = localStorage.getItem('current_language') === 'nl' ? nl : en;
    // const defaultLanguage = en;
    T.setTexts(defaultLanguage);

    console.log('Render App.js');

    return (
        <Provider store={store}>
            <BrowserRouter>
                <ScrollToTop>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{T.translate('metaHomeTitle')}</title>
                        <meta name="description" content={T.translate('metaHomeDescription')} />
                        <meta name="language" content={T.translate('metaGeneralLanguage')} />
                    </Helmet>
                    <Switch>
                        <Route exact path={'/'} component={Home} />
                        <Route path={'/contact'} component={Page(<Contact />)} />
                        <Route path={'/green-deals'} component={Page(<GreenDeals />)} />
                        <Route exact path={'/model-30'} component={Models} />
                        <Route path={'/model-45'} component={Models} />
                        <Route path={'/model-100'} component={Models} />
                        <Route path={'/custom'} component={Models} />
                        <Route path={'/our-vision'} component={Page(<OnzeVisie />)} />

                        <Route path={'/projects/model-100-at-work'} component={InActie} />
                        <Route path={'/projects/loxam-rental'} component={InActie} />
                        <Route path={'/projects/boels-rental'} component={InActie} />
                        <Route path={'/projects/dutch-army'} component={InActie} />
                        <Route path={'/projects/schiphol-airport'} component={InActie} />
                        {/*<Route path={'/projects/huur-de-hybride-groene-aggregaat-voor-uw-evenement'} component={InActie} />*/}
                        <Redirect from="/projects" to="/projects/model-100-at-work" />

                        <Route component={Page(<PageNotFound />)} />
                    </Switch>
                </ScrollToTop>
            </BrowserRouter>
        </Provider>
    );
}

export default App;
