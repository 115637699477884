import React from 'react';
import i18n from 'i18n-react';

const MadeBy = () => {
    return (
        <div className="made-by">
            {i18n.translate('footerMadeby')}{' '}
            <a href="https://www.appyourweb.nl" rel="noopener noreferrer" target="_blank" aria-label={'PWA developed by App your Web'}>
                App your Web
            </a>
        </div>
    );
};

export default MadeBy;
