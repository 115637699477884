export default {
    // Buttons
    messageButton: 'Message',
    askQuestionButton: 'Ask a question',

    ////////////////////////////////////////////////////
    //MODAL
    ////////////////////////////////////////////////////
    modalCloseButton: 'Close',
    modalQuestionTitle: 'Ask us your question',
    modalCallUs: 'Call us on working days \n from 9 AM till 5 PM CEST \n at +31 299 – 41 61 36',
    modalUseThisForm: 'Or use this contactform',
    modalThanksForMessage: 'Many thanks for your message! We will respond as soon as possible.',
    modalFieldName: 'Name',
    modalFieldCompany: 'Company',
    modalFieldTel: 'Telephone',
    modalFieldEmail: 'E-mail',
    modalFieldMessage: 'Question',
    modalSendButton: 'Send',
    modalModelTitle: 'Can I help you with', // Staat op 2 plaatsen in code. Krijgt modelversie mee op website: Can I help you with Model 45?
    modalDealerTitle: 'Become a dealer!',
    ////////////////////////////////////////////////////
    //END MODAL
    ////////////////////////////////////////////////////

    ////////////////////////////////////////////////////
    //GENERAL
    ////////////////////////////////////////////////////
    GeneralNetherlands: 'The Netherlands',
    model5: 'Model 5',
    model30: 'Model 30',
    model45: 'Model 45',
    model100: 'Model 100',
    custom: 'Custom',
    ////////////////////////////////////////////////////
    //END GENERAL
    ////////////////////////////////////////////////////

    ////////////////////////////////////////////////////
    //CONTACT PAGE
    ////////////////////////////////////////////////////
    contactPerson1Title: "CCO",
    contactPerson2Title: "Sales Director",
    contactPerson3Title: "Project Manager Int.",
    ContactHowToFindUs: "Locate our HQ and production facility",
    ContactVisitAdres: "Address",
    ContactSalesDepartment: "Sales Department",
    ContactDealerDepartment: "Dealers",
    contactTitle: "Meet our international team",
    ////////////////////////////////////////////////////
    //END CONTACT PAGE
    ////////////////////////////////////////////////////

    ////////////////////////////////////////////////////
    //MENU
    ////////////////////////////////////////////////////
    menuCustom: 'custom',
    menuDealers: 'dealers',
    menuProjects: 'projects',
    menuVision: 'vision',
    menuContact: 'contact',
    menuModel5: 'model 5',
    menuModel30: 'model 30',
    menuModel45: 'model 45',
    menuModel100: 'model 100',
    menuModels: 'models',
    ////////////////////////////////////////////////////
    //END MENU
    ////////////////////////////////////////////////////

    ////////////////////////////////////////////////////
    //FOOTER
    ////////////////////////////////////////////////////
    footerMenuModel5: 'model 5',
    footerMenuModel30: 'model 30',
    footerMenuModel45: 'model 45',
    footerMenuModel100: 'model 100',
    footerMenuCustom: 'custom',
    footerMenuVision: 'vision',
    footerMenuDealers: 'dealers',
    footerMenuContact: 'contact',
    footerMenuProjects: 'projects',
    footerMadeby: 'webapp powered by', //+ modules.app
    footerVat: 'EORI',
    footerCoc: 'COC',
    ////////////////////////////////////////////////////
    //END FOOTER
    ////////////////////////////////////////////////////

    ////////////////////////////////////////////////////
    //HOMEPAGE
    ////////////////////////////////////////////////////

    heroSlogan: 'Sustainable off-grid energy solutions',
    heroSubslogan: 'anywhere, anytime',
    heroArrowText: 'discover',
    homeDiscoverTitle: 'Discover the Green Generators',
    homeDiscoverSubTitle: 'the mobile hybride energy saver',
    homeAboutText:
        'At a time when environmental saving is becoming increasingly important, it is unthinkable to still use polluting, conventional generators. The Green Generators therefore offers a hybrid solution in which fuel and solar power come together to reduce CO2 emissions.',
    homeAboutText2:
        'Our hybride generator is a true energy saver with a CO2 reduction of more than 90%! This due to the significantly lower fuel consumption, thus not only the environment, but also your wallet is saved.',
    homeAboutTextTitle: 'Why choose our generator?',

    //Homepage model selector
    homeModelSelectorContactTitle: 'Interested in this model?', //Wordt ook gebruikt op de models page
    homeModelSelectorContactTitleSub: 'Feel free to contact us', //Wordt ook gebruikt op models page
    homeModelSelectorContactTitleMobile: 'Interested in this model?', //Wordt ook gebruikt op models page
    homeModelSelectorMessage: 'Send a message', // Wordt ook op models and projects page gebruikt
    homeModelSelectorMessageMobile: 'Message', // Wordt ook op models and projects page gebruikt
    homeModelSelectorCallUsMobile: 'Call us', // Wordt ook op models and projects page gebruikt

    homeModelSelectorReadMore: 'Read more',
    homeModelSelectorReadMoreMobile: 'Read more',

    homeModel5Text:
        'Our smallest model proves that you can be environmentally friendly even without solar panels. With its small 8 ft container, it is a fast and practical deployable hybrid generator of 5 kVA for every occasion.', //Model teksten worden ook op models page gebruikt
    homeModel30Text:
        'Our entry-level model with low-noise generator and solar panels. This compact 30kVA hybrid green powerhouse can easily be placed in places with limited space due to its 10 ft container.', //Model teksten worden ook op models page gebruikt
    homeModel45Text:
        'With 45 kVA this hybrid generator has more power than Model 30. And that while the same 10 ft container is retained. For those who require a unit with more power within a limited space.', //Model teksten worden ook op models page gebruikt
    homeModel100Text:
        'Thanks to its 100kVA, our largest hybrid generator is an all-rounder for the heavier work. With its 20-ft container, there is more room for solar panels, which means that the efficiency is also better.', //Model teksten worden ook op models page gebruikt

    homePossibleSaving: 'Possible savings',
    homeVersusThegreengenerator: 'The Green Generator',
    homeVersusConventional: 'Conventional',
    homeVersus: 'versus',
    homeFuelCostsYear: 'Fuel costs per year',
    homeCO2EmissionsYear: 'CO2 emmisions per year',

    homeModel5CostsGreen: '$5.749',
    homeModel5CostsRed: '$38.324',
    homeModel30CostsGreen: '$9.584',
    homeModel30CostsRed: '$63.892',
    homeModel45CostsGreen: '$15.331',
    homeModel45CostsRed: '$102.274',
    homeModel100CostsGreen: '$41.536',
    homeModel100CostsRed: '$281.195',
    homeModel5CO2Green: '31.904lbs',
    homeModel5CO2Red: '212.702lbs',
    homeModel30CO2Green: '53.193lbs',
    homeModel30CO2Red: '354.609lbs',
    homeModel45CO2Green: '85.085lbs',
    homeModel45CO2Red: '567.629lbs',
    homeModel100CO2Green: '230.529lbs',
    homeModel100CO2Red: '1.560.661lbs',

    homeBannerTitle:'Become a dealer',
    homeBannerTitleMobile:'Join our network',
    homeBannerText1:'Be part of our innovative green technologies group and start selling The Green Generators in your region.',
    homeBannerText2:'Contact us and discover the possibilities.', 

    //Icons advantages homepage
    iconProfitable: 'Profitable',
    iconProfitableSub: 'The Green Generator is earned back within 1 year.',
    iconCO2: '90% CO2 reduction',
    iconCO2Sub: 'Use less fuel and save the environment.',
    iconEasyToUse: 'Easy to use',
    iconEasyToUseSub: 'Only one button to activate The Green Generator.',
    iconLowNoise: 'Low noise',
    iconLowNoiseSub: 'Avoid noise! Our hybride generators are low-noise.',

    ////////////////////////////////////////////////////
    //END HOMEPAGE
    ////////////////////////////////////////////////////

    ////////////////////////////////////////////////////
    //MODELS PAGE
    ////////////////////////////////////////////////////
    modelsCustomContactTitle: 'Your own custom generator?',
    modelsCustomContactTitleSub: 'We will build it for you',

    generalReadmoreRead: 'Read',
    generalReadmoreMore: 'more',
    generalReadmoreLess: 'less',

    modelsSpecsButton: 'Specs',
    modelsSpecsBlockTitle: 'specifications', //Krijgt model mee: model 5 specifications

    modelsSavingsText1: 'On annual basis',
    modelsSavingsText2: 'Fuel:',
    modelsSavingsText3: 'CO2:',
    modelsSavingsTitle: 'Possible savings',

    modelLabel1: 'EASY TO PLACE',
    modelLabel2: 'EASY AUTOMATIC INSTALLATION',
    modelLabel3: 'EASY TO USE',
    modelLabel4: 'EASY TO CONNECT',
    modelLabel5: 'READY IN 2 MINUTES',

    modelLabelCustom1: 'RESEARCH & DEVELOPMENT',
    modelLabelCustom2: 'CUSTOM SOFTWARE',
    modelLabelCustom3: 'IN-HOUSE MANUFACTURING',
    modelLabelCustom4: 'CUSTOM MODULES',
    modelLabelCustom5: 'ENDLESS POSSIBILITIES',

    modelsCustomText1:
        'We can also develop the Green Generator completely custom-made for you. As a one-stop shop, we house all the necessary services to ensure that the development process runs smoothly. The entire process runs within our own organization, so you will be closely involved in the development of your own product.',
    modelsCustomText2:
        'We have already developed various modules, such as a 4G mast, shed shack or water treatment unit. But of course also a container with its own company color, logo and specific dimensions are possible. Please, do not hesitate to contact us for a specific wish. We are not afraid of a challenging project. Your own custom made hybride energy saver.',
    modelsDefaultText:
        'Interested in buying this unit? Please contact us for current availability and prices. We have a service that is available 24/7. Knowledge of the hybrid generator is therefore not necessary, because you can always contact us for questions or malfunctions. \n\n If you are interested in becoming an official licensed reseller of The Green Generators, please contact dealer support.',

    models5Spec1: '8 ft container',
    models5Spec2: '11kVA generator',
    models5Spec3: '5 kVA inverter',
    models5Spec4: 'Generator with 3 phase connection',
    models5Spec5: 'Generator without solar panels',
    models5Spec6: 'Battery storage 15kWh Lithium & 28kWh OLZ',

    models30Spec1: '10ft container',
    models30Spec2: '45 kVA generator',
    models30Spec3: '45 kVA inverter',
    models30Spec4: 'Generator with 3 phase connection',
    models30Spec5: '6 solar panel on rotating arm',
    models30Spec6: 'Battery storage 30kWh lithium',

    models45Spec1: '10ft container',
    models45Spec2: '60 kVA generator',
    models45Spec3: '45 kVA inverter',
    models45Spec4: 'Generator with 3 phase connection',
    models45Spec5: '6 solar panel on rotating arm',
    models45Spec6: 'Battery storage 30kWh lithium',

    models100Spec1: '20ft container',
    models100Spec2: '100 kVA generator',
    models100Spec3: '100 kVA inverter',
    models100Spec4: 'Generator with 3 phase connection',
    models100Spec5: '20 solar panel on rotating arm',
    models100Spec6: 'Battery storage 110kWh lithium',

    modelsCustomSpec1: 'In accordance with specific standards',
    modelsCustomSpec2: 'Generator for every showdown',
    modelsCustomSpec3: 'Inverter of your choice',
    modelsCustomSpec4: 'Personal design',
    modelsCustomSpec5: 'Custom modules',
    modelsCustomSpec6: 'Custom hybrid packages',

    modelsSavingsFuelCustom: 'custom',
    modelsSavingsCO2Custom: 'custom',
    modelsSavingsFuelModel5: '$35.537,-',
    modelsSavingsFuelModel30: '$59.245,-',
    modelsSavingsFuelModel45: '$94.847,-',
    modelsSavingsFuelModel100: '$261.446,-',
    modelsSavingsCO2Model5: '180.796,7 lbs',
    modelsSavingsCO2Model30: '301.416 lbs',
    modelsSavingsCO2Model45: '482.543,4 lbs',
    modelsSavingsCO2Model100: '1.330.133 lbs',

    ////////////////////////////////////////////////////
    //END MODELS PAGE
    ////////////////////////////////////////////////////

    ////////////////////////////////////////////////////
    //OUR VISION PAGE
    ////////////////////////////////////////////////////
    visionPageTitle: 'Our company',
    visionPageTitleSub: 'Who we are and what we want to accomplish',

    visionBlock1Title:'From ambition to product. Who we are.',
    visionBlock1TitleMobile:'Who we are.',
    visionBlock1InnerTitle:'Maru Systems',
    visionBlock1Text1:'The Green Aggregate finds its early origins in 2007 as RUSO technical services. The development of various innovative ideas and products went smoothly within RUSO, so that the step towards an umbrella organization was desired. Maru Systems was born from this: an umbrella organization that focuses on an international market for functionally sustainable, self-developed and innovative machines. These products are sold within the target group solar, off grid energy supply solutions.',
    visionBlock1Text2:'One of these innovative products is the Green Generator. A hybrid unit that offers an off-grid and sustainable alternative to polluting, conventional units. This product has been developed within our R&D department in The Netherlands, where we manage the entire process from the first sketch to the last bolt in our own workshop. This allows us to place the Green Generator as a reliable product and also provide adequate support for all functionalities. In addition, our R&D team continues to work on the development and improvement of the Green Generator at all times, so that it will continue to meet the latest environmental requirements and performance levels.',

    visionBlock2Title:'What is a hybride generator?',
    visionBlock2InnerTitle:'The Green Generator',
    visionBlock2Text1:'One of these innovative products is the Green Generator. A hybrid unit that offers an off-grid and sustainable alternative to polluting, conventional units. This product has been developed within our R&D department in The Netherlands, where we manage the entire process from the first sketch to the last bolt in our own workshop. This allows us to place the Green Generator as a reliable product and also provide adequate support for all functionalities. In addition, our R&D team continues to work on the development and improvement of the Green Generator at all times, so that it will continue to meet the latest environmental requirements and performance levels.',
    visionBlock2Text2:'To reduce CO2 emissions, the batteries are charged by solar panels that come out of the housing automatically. The whole installation is ready within 2 minutes. In this way you create an off-grid (independent) energy solution with care for the environment and your wallet in a short time.',
    visionBlock2Quote:'Our green generators offer sustainable alternatives to conventional generators at any location',

    visionBlock3Title:'What are the advantages?',
    visionBlock3InnerTitle:'Maru Systems',
    visionBlock3Text1:'The Green Generator has been developed from a simple vision: it must be functionally sustainable. As a producer, we not only deliver for the professional market. We would like to see that the Green Generator can be used by everyone. That is why the Green Generator is an interesting solution both functionally, financially as operationally.',
    visionBlock3Text2:'CO2 reduction is our greatest passion when it comes to developing all kinds of sustainable solutions within the energy requirement. This passion and our love for development has led the Green Generator to make a major contribution to improving emissions in relation to the environment.',
    visionBlock3Text3:'We consciously choose to keep the Green Generator as simple as possible in terms of operation, so that every user can easily manage it. The fact that the operation is simple does not mean that the technology behind it is! The Green Generator is, for example, equipped with a management system that can be controlled remotely. This allows you to monitor and control your hybrid unit at all times.',
    visionBlock3Text4:'Hybrid Generators also make noise, unfortunately. But thanks to the smart functionality of the Green Generator, you can control it yourself. The Green Generator only makes noise when it is needed. This can be arranged entirely by the user; you determine when the generator starts. For example: is it desirable to continue a night in urban areas without noise? Then, thanks to its hybrid functionality, the Green Aggregate can be set so that it provides power at night without producing even one decibel of sound.',

    
    iconVisionProfitable: 'Profitable',
    iconVisionProfitableSub: 'The Green Generator is earned back within 1 year.',
    iconVisionCO2: '90% CO2 reduction',
    iconVisionCO2Sub: 'Use less fuel and save the environment.',
    iconVisionEasyToUse: 'Easy to use',
    iconVisionEasyToUseSub: 'Only one button to activate The Green Generator.',
    iconVisionLowNoise: 'Low noise',
    iconVisionLowNoiseSub: 'Avoid noise! Our hybride generators are low-noise.',
    ////////////////////////////////////////////////////
    //END OUR VISION PAGE
    ////////////////////////////////////////////////////

    ////////////////////////////////////////////////////
    //PROJECTS
    ////////////////////////////////////////////////////
    projectsTitle: 'The Green Generators at work',
    projectsTitle2: 'The Green Generators at work',
    projectsTelButton: '+31 299 - 416136',
    projectsMessageButton: 'Send message',
    projectsContactTitle: 'Have a project in mind?',
    projectsContactTitleSub: 'Please contact us',

    projectsProject1Title:'Our powerhouse running',
    projectsProject1TitleSub:'Model 100',
    projectsProject1Text:'Model 100 is our strongest and biggest hybride generator. Capable of producing 100kVA continious. With its 20 solar panels, it can provide a big improvement on fuel usage. Because of its big capacity it can support large projects.',

    projectsProject2Title:'Our professional rental partner',
    projectsProject2TitleSub:'Loxam',
    projectsProject2Text:'One of our partners who started renting The Green Generators. We are happy to see Loxam be part of our network. If you are interesed in becoming a dealer of The Green Generators, please do not hesitate to contact us.',

    projectsProject3Title:'Rent our Green generator',
    projectsProject3TitleSub:'Boels',
    projectsProject3Text:'Our partner Boels has included The Green Generator in their rental program. Thanks to this collaboration, you can now also visit your favorite Boels location for our hybrid aggregate. Check with Boels for availability and rental prices.',

    projectsProject4Title:'The green power of military',
    projectsProject4TitleSub:'Dutch Army',
    projectsProject4Text:'The Dutch Army has deployed The Green Generator as a power supply for a base camp. As a result of this collaboration, Maru Systems is looking at the further development of the product on this market.',

    projectsProject5Title:'Clean energy among planes',
    projectsProject5TitleSub:'Schiphol',
    projectsProject5Text:'For client Volker Wessels, hybrid generators have been used at Schiphol for quite some time. The Green Generator is helping to achieve sustainability objectives on this project for this client.',

    //projectsProject6Title:'bvbv',
    //projectsProject6TitleSub:'rgr',
    //projectsProject6Text:'we3',


    ////////////////////////////////////////////////////
    //END PROJECTS
    ////////////////////////////////////////////////////    


    ////////////////////////////////////////////////////
    //META TAGS
    ////////////////////////////////////////////////////
    metaGeneralLanguage:'EN',

    //Homepage
    metaHomeTitle:'Clean Hybrid Energy | The Green Generators',
    metaHomeDescription:'Sustainable hybride generator. We offer different Green Generators for every solution. Buy your own or become a dealer in our network.',

    //Models page
    metaModel5Title:'MODEL 5 | The Green Generators',
    metaModel5Description:'Our smallest model proves that you can be environmentally friendly even without solar panels. With its small 8 ft container, it is a fast and practical deployable hybrid generator of 5 kVA for every occasion.', //Model teksten worden ook op models page gebruikt
    metaModel30Title:'MODEL 30 | The Green Generators',
    metaModel30Description:'Our entry-level model with low-noise generator and solar panels. This compact 30kVA hybrid green powerhouse can easily be placed in places with limited space due to its 10 ft container.', //Model teksten worden ook op models page gebruikt
    metaModel45Title:'MODEL 45 | The Green Generators',
    metaModel45Description:'With 45 kVA this hybrid generator has more power than Model 30. And that while the same 10 ft container is retained. For those who require a unit with more power within a limited space.', //Model teksten worden ook op models page gebruikt
    metaModel100Title:'MODEL 100 | The Green Generators',
    metaModel100Description:'Thanks to its 100kVA, our largest hybrid generator is an all-rounder for the heavier work. With its 20-ft container, there is more room for solar panels, which means that the efficiency is also better.', //Model teksten worden ook op models page gebruikt
    metaCustomTitle:'Custom Hybride Generator | The Green Generators',
    metaCustomDescription:'We can also develop the Green Generator completely custom-made for you. As a one-stop shop, we house all the necessary services to ensure that the development process runs smoothly. The entire process runs within our own organization, so you will be closely involved in the development of your own product.',

    //Dealers page
    metaDealersTitle:'Become a Dealer',
    //Projects page
    metaProjectsProject1Title:'Our most powerful hybride generator | The Green Generators',
    metaProjectsProject1Description:'Power for any clean project',
    metaProjectsProject2Title:'Loxam | The Green Generators',
    metaProjectsProject2Description:'Rent our hybride generator at Loxam rental',
    metaProjectsProject3Title:'Boels | The Green Generators',
    metaProjectsProject3Description:'Rent our hybride generator at Boels rental',
    metaProjectsProject4Title:'Hybride army power | The Green Generators',
    metaProjectsProject4Description:'The Dutch army powered by our hybride generator',
    metaProjectsProject5Title:'Clean energy at Schiphol airport | The Green Generators',
    metaProjectsProject5Description:'The Green Generators delivering green energy among planes',

    //Vision page
    metaVisionTitle:'Our Vision | The Green Generators',
    metaVisionDescription:'Read about our vision, who we are and what the Green Generator is.',

    //Contact page
    metaContactTitle:'Contact Us | The Green Generators',
    metaContactDescription:'Contact us for questions or if you would like to become a dealer.',

    //404
    metaPageNotFoundTitle:'404 Page Not Found | The Green Generators',
    metaPageNotFoundDescription:'Page does not exist',
    ////////////////////////////////////////////////////
    //END META TAGS
    ////////////////////////////////////////////////////


    ////////////////////////////////////////////////////
    //404 PAGE
    ////////////////////////////////////////////////////
    pageNotFoundTitle:'Page not found | The Green Generators',
    pageNotFoundText1:'The Green Generators can be found all over the world, but unfortunately not here.',
    pageNotFoundText2:'Maybe we can help you find one of our models.',
    pageNotFoundText3:'You can choose one below.',
    ////////////////////////////////////////////////////
    //END 404 PAGE
    ////////////////////////////////////////////////////


    ////////////////////////////////////////////////////
    //URL
    ////////////////////////////////////////////////////
    urlModel5:'/model-5',
    urlModel30:'/model-30',
    urlModel45:'/model-45',
    urlModel100:'/model-100',
    urlCustom:'/custom',
    urlContact:'/contact',
    urlVision:'/our-vision',
    urlProject1:'/projects/model-100-at-work',
    urlProject2:'/projects/loxam-rental',
    urlProject3:'/projects/boels-rental',
    urlProject4:'/projects/dutch-army',
    urlProject5:'/projects/schiphol-airport',
    ////////////////////////////////////////////////////
    //END URL
    ////////////////////////////////////////////////////
};
