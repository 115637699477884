// Modules
import React from 'react';
import i18n from 'i18n-react';

// Styling
import './OnzeVisie.scss';

// Images
import model100 from '../../img/home-models/model-100/huur-onze-groene-aggregaat-model-100-hybride-aggregaat-met-zonnepanelen-veel-vermogen-en-laag-co2-uitstoot-new.png';

import iconCO2 from '../../img/onze-visie/icon CO2.svg';
import iconGeluid from '../../img/onze-visie/icon geluid.svg';
import iconKnop from '../../img/onze-visie/icon knop.svg';
import iconWinst from '../../img/onze-visie/icon winstgevend.svg';
import Helmet from 'react-helmet';

class AccordionBox extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: false,
        };
    }

    setExpanded = expanded => {
        this.setState({ expanded });
    };
    render() {
        const { expanded } = this.state;
        const { type, content, text } = this.props;
        return (
            <div className={type}>
                <div className={'trigger'} onClick={() => this.setExpanded(!expanded)}>
                    <p>{text}</p>
                    <i className={'material-icons'}>{expanded ? 'arrow_drop_up' : 'arrow_drop_down'} </i>
                </div>
                <div className={expanded ? 'content-visible' : 'content-hidden'}>{content}</div>
            </div>
        );
    }
}

const OnzeVisie = props => {
    return (
        <main className={'onze-visie-page'}>
            <Helmet>
                <title>{i18n.translate('metaVisionTitle')}</title>
                <meta name="description" content={i18n.translate('metaVisionDescription')} />
                <meta name="language" content={i18n.translate('metaGeneralLanguage')} />
            </Helmet>
            <section className={'first-section'}>
                <h1>{i18n.translate('visionPageTitle')}</h1>
                <p>{i18n.translate('visionPageTitleSub')}</p>
            </section>
            <section>
                <AccordionBox
                    text={window.innerWidth < 751 ? (i18n.translate('visionBlock1Title')) : (i18n.translate('visionBlock1TitleMobile'))}
                    type={'box grey-box'}
                    content={
                        <div className="box-content">
                            <h3>{i18n.translate('visionBlock1InnerTitle')}</h3>
                            <p>
                            {i18n.translate('visionBlock1Text1')}
                            </p>
                            <img src={model100} alt= "de groene hybride aggregaat is ontwikkeld door maru systems en nu te huur"/>
                            <p>
                            {i18n.translate('visionBlock1Text2')}
                            </p>
                        </div>
                    }
                />
                <AccordionBox
                    text={(i18n.translate('visionBlock2Title'))}
                    type={'box blue-box'}
                    content={
                        <div className="box-content">
                            <h3>{i18n.translate('visionBlock2InnerTitle')}</h3>
                            <p>
                            {i18n.translate('visionBlock2Text1')}
                            </p>
                            <p>
                            {i18n.translate('visionBlock2Text2')}
                            </p>
                            <blockquote>{i18n.translate('visionBlock2Quote')}</blockquote>
                        </div>
                    }
                />
                <AccordionBox
                    text={(i18n.translate('visionBlock3Title')) }
                    type={'box green-box'}
                    content={
                        <div className="box-content">
                            <div className="header-with-icon">
                                <img src={iconWinst} alt= "de groene aagregaat is winstgevend"/>
                                <div>
                                    <h3>{i18n.translate('iconVisionProfitable')}</h3>
                                    <h5>{i18n.translate('iconVisionProfitableSub')}</h5>
                                </div>
                            </div>

                            <p>
                            {i18n.translate('visionBlock3Text1')}
                            </p>
                            <div className={'horizontal-rule'} />

                            <div className="header-with-icon">
                                <img src={iconCO2} alt= "de groene aagregaat is milieuvriendelijk"/>
                                <div>
                                    <h3>{i18n.translate('iconVisionCO2')}</h3>
                                    <h5>{i18n.translate('iconVisionCO2Sub')}</h5>
                                </div>
                            </div>

                            <p>
                            {i18n.translate('visionBlock3Text2')}

                            </p>
                            <div className={'horizontal-rule'} />

                            <div className="header-with-icon">
                                <img src={iconKnop} alt= "de groene aagregaat is gemakkelijk in gebruik" />
                                <div>
                                    <h3>{i18n.translate('iconVisionEasyToUse')}</h3>
                                    <h5>{i18n.translate('iconVisionEasyToUseSub')}</h5>
                                </div>
                            </div>

                            <p>
                            {i18n.translate('visionBlock3Text3')}

                            </p>
                            <div className={'horizontal-rule'} />

                            <div className="header-with-icon">
                                <img src={iconGeluid} alt= "de groene aagregaat is geluidsarm"/>
                                <div>
                                    <h3>{i18n.translate('iconVisionLowNoise')}</h3>
                                    <h5>{i18n.translate('iconVisionLowNoiseSub')}</h5>
                                </div>
                            </div>

                            <p>
                            {i18n.translate('visionBlock3Text4')}

                            </p>
                        </div>
                    }
                />
            </section>
            <div className={'onze-visie-background-image'} />
        </main>
    );
};

export default OnzeVisie;
