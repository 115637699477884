// Modules
import React from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';

// Components
import Gallery from '../../components/Gallery';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import i18n from 'i18n-react';

// Styling
import './InActie.scss';

import image1 from '../../img/model100/model-100-de-groene-aggregaat-hybride-off-grid-energie-oplossing-binnen-2-minuten-actief-2.jpg';
import image2 from '../../img/inactie/loxam-rental-hybride-generator-the-green-generators.jpg';
import image3 from '../../img/inactie/boels-verhuurt-de-groene-hybride-aggregaat-online.jpg';
import image4 from '../../img/inactie/defensie-en-de-groene-aggregaat-voor-hybride-schone-energie.jpg';
import image5 from '../../img/inactie/schiphol-huurt-groene-aggregaat-klm.jpg';
//import image6 from '../../img/inactie/lokaal-evenement-kleinschalig-huurt-groene-aggregaat-festival.jpg';
import MadeBy from '../../components/MadeBy';

const getStartIndexByProject = routeProps => {
    const path = routeProps.match.path;
    let startIndex = 0;

    switch (path) {
        case '/projects/loxam-rental':
            startIndex = 1;
            break;

        case '/projects/boels-rental':
            startIndex = 2;
            break;

        case '/projects/dutch-army':
            startIndex = 3;
            break;

        case '/projects/schiphol-airport':
            startIndex = 4;
            break;

        // case '/projects/huur-de-hybride-groene-aggregaat-voor-uw-evenement':
        //     startIndex = 5;
        //     break;

        default:
            startIndex = 0;
            break;
    }

    return startIndex;
};

const getProjectText = (routeProps, textType) => {
    const path = routeProps.match.path;
    const projectTexts = {
        '/projects/model-100-at-work': {
            title: 'projectsProject1TitleSub',
            short: 'projectsProject1Title',
            meta: (
                <Helmet>
                    <title>{i18n.translate('metaProjectsProject1Title')}</title>
                    <meta name="description" content={i18n.translate('metaProjectsProject1Description')} />
                    <meta name="language" content={i18n.translate('metaGeneralLanguage')} />
                </Helmet>
            ),
            info: 'projectsProject1Text',
        },
        '/projects/loxam-rental': {
            title: 'projectsProject2TitleSub',
            short: 'projectsProject2Title',
            meta: (
                <Helmet>
                    <title>{i18n.translate('metaProjectsProject2Title')}</title>
                    <meta name="description" content={i18n.translate('metaProjectsProject2Description')} />
                    <meta name="language" content={i18n.translate('metaGeneralLanguage')} />
                </Helmet>
            ),
            info: 'projectsProject2Text',
        },
        '/projects/boels-rental': {
            title: 'projectsProject3TitleSub',
            short: 'projectsProject3Title',
            meta: (
                <Helmet>
                    <title>{i18n.translate('metaProjectsProject3Title')}</title>
                    <meta name="description" content={i18n.translate('metaProjectsProject3Description')} />
                    <meta name="language" content={i18n.translate('metaGeneralLanguage')} />
                </Helmet>
            ),
            info: 'projectsProject3Text',
        },
        '/projects/dutch-army': {
            title: 'projectsProject4TitleSub',
            short: 'projectsProject4Title',
            meta: (
                <Helmet>
                    <title>{i18n.translate('metaProjectsProject4Title')}</title>
                    <meta name="description" content={i18n.translate('metaProjectsProject4Description')} />
                    <meta name="language" content={i18n.translate('metaGeneralLanguage')} />
                </Helmet>
            ),
            info: 'projectsProject5Text',
        },
        '/projects/schiphol-airport': {
            title: 'projectsProject5TitleSub',
            short: 'projectsProject5Title',
            meta: (
                <Helmet>
                    <title>{i18n.translate('metaProjectsProject5Title')}</title>
                    <meta name="description" content={i18n.translate('metaProjectsProject5Description')} />
                    <meta name="language" content={i18n.translate('metaGeneralLanguage')} />
                </Helmet>
            ),
            info: 'projectsProject5Text',
        },
        // '/projects/huur-de-hybride-groene-aggregaat-voor-uw-evenement': {
        //     title: 'Festival',
        //     short: 'Ook voor uw evenement',
        //     meta: (
        //         <Helmet>
        //             <title>Festival | The Green Generators</title>
        //             <meta name="description" content="Ook voor uw evenement" />
        //         </Helmet>
        //     ),
        //     info:
        //         'De Groene Aggregaat draait al jaren mee op de meest uiteenlopende festivals. Vaak op de achtergrond maar altijd vooraan op gebied van Co2 reductie. Wij helpen event organisaties met het verduurzamen van hun festivals.',
        // },
    };

    return projectTexts[path][textType];
};

const InActie = props => {
    const images = [
        {
            original: image1,
            thumbnail: image1,
        },
        {
            original: image2,
            thumbnail: image2,
        },
        {
            original: image3,
            thumbnail: image3,
        },
        {
            original: image4,
            thumbnail: image4,
        },
        {
            original: image5,
            thumbnail: image5,
        },
        // {
        //     original: image6,
        //     thumbnail: image6,
        // },
    ];

    return (
        <>
            {getProjectText(props.routeProps, 'meta')}
            <Header />
            <main className="page-in-action">
                <h1 className="visually-hidden">{i18n.translate('projectsTitle')}</h1>
                <section className="in-action">
                    <h2 className="in-action__heading">{i18n.translate('projectsTitle2')}</h2>
                    <p className="in-action__subheading">{i18n.translate(getProjectText(props.routeProps, 'short'))}</p>
                    <div className="in-action__gallery">
                        <div className="in-action__gallery--left">
                            <Gallery startIndex={getStartIndexByProject(props.routeProps)} navigateToAction={props.navigateToAction} items={images} />
                        </div>
                        <div className="in-action__gallery--right">
                            <h1 className="project-title">{i18n.translate(getProjectText(props.routeProps, 'title'))}</h1>
                            <div className="desktop-only">
                                <p>{i18n.translate(getProjectText(props.routeProps, 'info'))}</p>

                                <h2> {i18n.translate('projectsContactTitle')}</h2>
                                <p> {i18n.translate('projectsContactTitleSub')}</p>
                                <div className="comparison__contact-container--desktop">
                                    <div className="comparison__button contact-button contact-button--phone site-button" onClick={() => window.open('tel:+31299416136')}>
                                        <a className="site-link" href="tel:+31299416136" aria-label="Bel met de groene aggregaat">
                                            {i18n.translate('projectsTelButton')}
                                        </a>
                                    </div>
                                    <div
                                        className="comparison__button contact-button contact-button--mail site-button"
                                        onClick={() => window.open('mailto:verhuur@degroeneaggregaat.nl')}
                                    >
                                        <a className="site-link" href="mailto:verhuur@degroeneaggregaat.nl" aria-label="Mail de groene aggregaat">
                                            {i18n.translate('projectsMessageButton')}
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="mobile-only">
                                <br />
                                <p>{i18n.translate(getProjectText(props.routeProps, 'info'))}</p>
                            </div>
                        </div>
                    </div>
                    <div className="mobile-contact mobile-only">
                        <p>Groene stroom op jouw evenement?</p>

                        <div className="contact-button-container">
                            <div className="contact-button-mobile contact-button-mobile--phone site-button" onClick={() => window.open('tel:+31299416136')}>
                                <a className="site-link" href="tel:+31299416136" aria-label="Bel met de groene aggregaat" rel={'noopener'}>
                                    Bel ons
                                </a>
                            </div>
                            <div className="contact-button-mobile contact-button-mobile--mail site-button" onClick={() => window.open('mailto:verhuur@degroeneaggregaat.nl')}>
                                <a className="site-link" href="mailto:verhuur@degroeneaggregaat.nl" aria-label="Mail de groene aggregaat" rel={'noopener'}>
                                    Bericht
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
            <MadeBy />
        </>
    );
};

function mapStateToProps(state, props) {
    return { routeProps: props };
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    navigateToAction: index => {
        console.log('probeer te navigeren --> ', index, ownProps);

        let project = 'model-100-at-work';

        if (index === 1) {
            project = 'loxam-rental';
        }

        if (index === 2) {
            project = 'boels-rental';
        }

        if (index === 3) {
            project = 'dutch-army';
        }

        if (index === 4) {
            project = 'schiphol-airport';
        }

        // if (index === 5) {
        //     project = 'huur-de-hybride-groene-aggregaat-voor-uw-evenement';
        // }

        ownProps.history.push('/projects/' + project);
    },
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InActie);
