// Modules
import React from 'react';
import i18n from 'i18n-react';

// Images
import Johan_de_Leeuw from '../../img/contact/Johan_de_Leeuw.jpg';
import Ryan_Colle from '../../img/contact/Ryan-Colle2.jpg';
import Daniel_Dummy from '../../img/contact/Daniel_Dummy.jpg';
import WASVG from '../../img/whatsapp.svg';
import dgaLogoSVG from '../../img/contact-the-green-generators-and-become-a-dealer-or-reseller-of-a-hybride-sustainable-clean-generator.svg';

// Styling
import './contact.scss';
import './contact-mobile.scss';
import './team.scss';
import './team-mobile.scss';
import Helmet from 'react-helmet';

const teamMembersData = [
    {
        name: 'Daniel',
        fullName: 'Daniel Krug',
        photoSource: Daniel_Dummy,
        functionDisplay: 'contactPerson1Title',
        contactInfo: {
            email: 'sales@thegreengenerators.com',
            phone: '31299416136',
            phoneDisplay: '+31 299 - 41 61 36',
        },
    },
    {
        name: 'Ryan',
        fullName: 'Ryan Colle',
        photoSource: Ryan_Colle,
        functionDisplay: 'contactPerson3Title',
        contactInfo: {
            email: 'sales@thegreengenerators.com',
            phone: '31299416136',
            phoneDisplay: ' +31 299 – 41 61 36',
        },
    },
];

const Contact = () => (
    <main className="page-main">
        <Helmet>
            <title>{i18n.translate('metaContactTitle')}</title>
            <meta name="description" content={i18n.translate('metaContactDescription')} />
            <meta name="language" content={i18n.translate('metaGeneralLanguage')} />
        </Helmet>
        <h1 className="visually-hidden">Contact</h1>
        <section className="team">
            <div className="team__wrapper">
                <h2 className="team__heading">{i18n.translate('contactTitle')}</h2>
                <ul className="team__list-desktop site-list">
                    {teamMembersData.map(member => (
                        <TeamMember key={member.fullName} {...member} />
                    ))}
                </ul>

                <ul className="team__list-mobile site-list">
                    {teamMembersData.map(member => (
                        <TeamMember key={member.fullName} isMobile={true} {...member} />
                    ))}
                </ul>

                <b className="team__direction">{i18n.translate('ContactHowToFindUs')}</b>
                <div className="contact">
                    <div className="contact__address-container">
                        <h3 className="contact__heading">{i18n.translate('ContactVisitAdres')}</h3>
                        <p className="contact__address">
                            Vredenburghweg 2<br />
                            1461 GT Zuidoostbeemster, {i18n.translate('GeneralNetherlands')}
                        </p>
                        <p className="contact__address">
                            <b>{i18n.translate('ContactSalesDepartment')}</b>
                            <br />
                            +31 299 – 41 61 36
                            <br />
                            sales@thegreengenerators.com
                        </p>
                        {/*}<p className="contact__address">
                            <b>{i18n.translate('ContactDealerDepartment')}</b>
                            <br />
                            +31 299 – 41 61 36
                            <br />
                            sales@thegreengenerators.com
                    </p>{*/}
                    </div>
                    <img className="contact__logo" src={dgaLogoSVG} width="600" height="224" alt="De groene aggregaat logo" />
                </div>
            </div>

            <div className="contact__photo" />
        </section>
    </main>
);

const TeamMember = props => {
    const { photoSource, name, fullName, functionDisplay, contactInfo, isMobile } = props;

    if (isMobile) {
        return (
            <li className="team-member-mobile">
                <div className="team-member-mobile__wrapper">
                    <div className="team-member-mobile__info">
                        <p className="team-member-mobile__name">{fullName}</p>
                        <span className="team-member-mobile__function">{i18n.translate(functionDisplay)}</span>
                    </div>
                    <img className="team-member-mobile__photo" src={photoSource} width="215" height="222" alt={`Foto van onze medewerker ${fullName}`} />
                </div>
                <div className="team-member-mobile__contact">
                    <a className="team-member__contact-mobile contact-button-mobile contact-button-mobile--phone" href={`tel:+${contactInfo.phone}`}>
                        {name}
                    </a>
                    <a className="team-member__contact-mobile contact-button-mobile contact-button-mobile--mail" href={`mailto:${contactInfo.email}`}>
                        {i18n.translate('messageButton')}
                    </a>
                    <a className="whatsapp-link" rel="noopener" target={'_blank'} href={`https://wa.me/${contactInfo.phone}`}>
                        <img className={'wasvg'} src={WASVG} alt={'Whatsapp button ' + name} />
                    </a>
                </div>
            </li>
        );
    }

    return (
        <li className="team-member">
            <div className="team-member__container">
                <img className="team-member__photo" src={photoSource} width="215" height="222" alt={`Foto van onze medewerker ${fullName}`} />
            </div>
            <div className="team-member__info">
                <p className="team-member__name">{fullName}</p>
                <span className="team-member__function">{i18n.translate(functionDisplay)}</span>
                <a className="team-member__contact contact-button contact-button--phone site-button" href={`tel:+${contactInfo.phone}`}>
                    {contactInfo.phoneDisplay}
                </a>
                <a className="whatsapp-link" rel="noopener" target={'_blank'} href={`https://wa.me/${contactInfo.phone}`}>
                    <img className={'wasvg'} src={WASVG} alt={'Whatsapp button ' + name} />
                </a>
                <a className="team-member__contact contact-button contact-button--mail site-button" href={`mailto:${contactInfo.email}`}>
                    {contactInfo.email}
                </a>
                <div className="team-member__contact-container">
                    <a className="team-member__contact-mobile contact-button-mobile contact-button-mobile--phone" href={`tel:+${contactInfo.phone}`}>
                        {name}
                    </a>
                    <a className="team-member__contact-mobile contact-button-mobile contact-button-mobile--mail" href={`mailto:${contactInfo.email}`}>
                        {i18n.translate('messageButton')}
                    </a>
                </div>
            </div>
        </li>
    );
};

export default Contact;
