import React from 'react';
import Slider from 'react-slick/lib';
import i18n from 'i18n-react';

import model5 from '../../img/home-models/model-5/huur-onze-groene-aggregaat-model-5-een-hybride-off-grid-stroom-oplossing-klein.png';
import model30 from '../../img/home-models/model-30/huur-onze-groene-aggregaat-model-30-de-hybride-besparende-aggregaat-met-zonnepanelen-klein.png';
import model45 from '../../img/home-models/model-45/huur-onze-groene-aggregaat-model-45-bespaar-op-milileu-en-brandstof-met-de-hybride-stroom-oplossing-klein.png';
import model100 from '../../img/home-models/model-100/huur-onze-groene-aggregaat-model-100-hybride-aggregaat-met-zonnepanelen-veel-vermogen-en-laag-co2-uitstoot-klein.png';

import './404.scss';
import { NavLink } from 'react-router-dom';
import Helmet from 'react-helmet';

const PageNotFound = () => (
    <main className={'not-found'}>

        <Helmet>
            <title>{i18n.translate('MetaPageNotFoundTitle')}</title>
            <meta name="description" content= {i18n.translate('metaPageNotFoundTitle')} />
            <meta name="language" content= {i18n.translate('metaPageNotFoundDescription')} />
            <meta name="robots" content="noindex, nofollow" />
        </Helmet>

        <h1>{i18n.translate('pageNotFoundTitle')}</h1>
        <p>{i18n.translate('pageNotFoundText1')}</p>
        <p>{i18n.translate('pageNotFoundText2')}</p>
        <p>{i18n.translate('pageNotFoundText3')}</p>


        <div className="container">
            <Slider
                slidesToShow={1}
                initialSlide={0}
                afterChange={args => {
                    // this.setActiveModel(args);
                }}
                onSwipe={() => console.log('Finger is not on screen anymore --> ')}
                {...{
                    dots: false,
                }}
            >
                <div className={'model-image-wrapper model-5'}>
                    <img src={model5} alt="Model 5" />
                    <h2>
                        <NavLink to={'/model-5'}>Model 5</NavLink>
                    </h2>
                </div>
                <div className={'model-image-wrapper model-30'}>
                    <img src={model30} alt="Model 30" />
                    <h2>
                        <NavLink to={'/model-30'}>Model 30</NavLink>
                    </h2>
                </div>
                <div className={'model-image-wrapper model-45'}>
                    <img src={model45} alt="Model 45" />
                    <h2>
                        <NavLink to={'/model-45'}>Model 45</NavLink>
                    </h2>
                </div>
                <div className={'model-image-wrapper model-100'}>
                    <img src={model100} alt="Model 100" />
                    <h2>
                        <NavLink to={'/model-100'}>Model 100</NavLink>
                    </h2>
                </div>
            </Slider>
        </div>

        <div style={{ height: window.innerHeight - 600 }} />
    </main>
);

export default PageNotFound;
